import axiosPost from "./axiosPost";

export const RNUsuarioIns = async (data, credentials) => {
    const body = {
        service: "RNUsuarioIns",
        params: {
            usuarioNroDeDocumentoX: data?.usuarioNroDeDocumentoX,
            usuarioApellidos: data?.lastName,
            usuarioNombres: data?.name,
            usuarioSexo: data?.usuarioSexo,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
