import React, { useEffect, useRef, useState } from 'react'
import { CircularIndeterminate, FormButton } from 'components'
import { FormikDateInput, FormikSelectInput, FormikTextInput } from 'components/UI/Inputs/FormikInputs';
import { Form, Formik } from "formik";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RNESArticulosGet } from 'services/services-mia/RNESArticulosGet';
import { RNESRubrosGet } from 'services/services-mia/RNESRubrosGet';
import styles from "styles/pages/ModalActions.module.scss";
import * as yup from "yup";
import { RNPSAsignacionIns } from 'services/services-mia/RNPSAsignacionIns';
import { RNPSProgramaGet } from 'services/services-mia/RNPSProgramaGet';

export const InsertModal = ({ updateList, roles, user, closeModal, coords }) => {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [inRequest, setInRequest] = useState(false);
    const credentials = useRecoilValue(userCredentials);
    const [programas, setProgramas] = useState([]);
    const [fechaSelected, setFechaSelected] = useState({ rnpsProgramaFechaInicio: "01-01-2000", rnpsProgramaFechaFin: "01-01-2030" });
    const formikRef = useRef();
    let date = new Date();
    date.setDate(date.getDate() - 1)
    const getProgramas = async (values) => {
        // setLoading(true);
        const response = await RNPSProgramaGet(credentials);
        setFechaSelected();
        setProgramas(response);
        // setLoading(false);
    }
    const handleCreateAsignacion = async (values) => {
        const response = await RNPSAsignacionIns(
            credentials,
            { usuarioMerchantId: user.id, cant: values.cant, fecha: values.fecha, programa: values.programa, rubroId: "PECRA" },
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Asignación creada correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }

    const dataFormik = {
        date: { label: "Fecha" },
    }

    useEffect(() => {
        getProgramas({});
    }, [])
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                userId: "",
                cant: "",
                fecha: "",
                programa: "",
                rubroId: "Alimentos",
            }}
            validationSchema={
                yup.object({
                    cant: yup.number("Debe ingresar una cantidad").required("Debe ingresar una cantidad"),
                    fecha: yup.date("Debe seleccionar una fecha").required("Debe seleccionar una fecha").when('programa', (programa, schema) =>
                        schema.test({
                            name: 'within-two-months',
                            test: (fecha) => {
                                if (!fecha) return true;
                                const maxDate = new Date(fechaSelected?.rnpsProgramaFechaFin);
                                maxDate.setDate(maxDate.getDate() + 1)
                                const minDate = new Date(fechaSelected?.rnpsProgramaFechaInicio);
                                return fecha >= minDate && fecha <= maxDate;
                            },
                            message: 'Fecha fuera de rango',
                        })
                    ),
                    programa: yup.string("Debe ingresar un programa").max(20, "Debe tener menos de 20 caracteres").required("Debe ingresar un programa"),
                })
            }
            onSubmit={(values) => {
                handleCreateAsignacion(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="rubroId"
                            labelText="Rubro"
                            disabled={true}
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="programa"
                            labelText="Programa"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("programa", e.target.value);
                                setFechaSelected(programas.find((programa) => programa.rnpsProgramaId === Number(e.target.value)));
                            }}
                        >
                            <option value="" disabled selected={true}>
                                Seleccione
                            </option>
                            {programas.map(({ rnpsProgramaId, rnpsProgramaNombre, rnpsProgramaVigente }) => (
                                rnpsProgramaVigente === "Y" && <option
                                    key={rnpsProgramaId}
                                    data-id={rnpsProgramaId}
                                    value={rnpsProgramaId}
                                >
                                    {rnpsProgramaNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="cant"
                            labelText="Monto"
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fecha"
                            labelText="Fecha disponible"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fecha", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Agregar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    )
}
