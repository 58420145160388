import React, { useEffect, useState } from 'react'
import { Grid, Autocomplete, TextField, Button, Box } from '@mui/material'
import { useService } from 'components/route/ServiceRoute'
import { RNDespachanteGet } from 'services/services-mia/RNDespachanteGet';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RNBeneficiarioGet } from 'services/services-mia/RNBeneficiarioGet';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormikDateInput } from 'components/Filters/FormikInputs';
import * as XLSX from 'xlsx';
import { RNESInformeGet } from 'services/services-mia/RNESInformeGet';
import moment from 'moment';
import { RNESRubrosGet } from 'services/services-mia/RNESRubrosGet';
import { RNESArticulosGet } from 'services/services-mia/RNESArticulosGet';

const validationSchema = Yup.object({
    despachante: Yup.object().nullable().required('Requerido'),
    beneficiario: Yup.object().nullable().required('Requerido'),
    articulo: Yup.object().nullable().required('Requerido'),
    fechadesde: Yup.date()
        .required('Requerido')
        .when('fechahasta', (fechahasta, schema) =>
            schema.test({
                name: 'within-two-months',
                test: (fechadesde) => {
                    if (!fechadesde || !fechahasta) return true;
                    const maxDate = new Date(fechahasta);
                    maxDate.setMonth(maxDate.getMonth() - 2);
                    return fechadesde >= maxDate && fechadesde <= new Date();
                },
                message: 'La fecha desde no puede ser anterior a dos meses.',
            }).test({
                name: 'before-or-equal-to-fechahasta',
                test: (fechadesde) => {
                    if (!fechadesde || !fechahasta) return true;
                    return fechadesde <= new Date(fechahasta);
                },
                message: 'La fecha desde no puede ser posterior a la fecha hasta.',
            })
        ),
    fechahasta: Yup.date()
        .required('Requerido')
        .max(new Date(), 'La fecha hasta no puede ser posterior a hoy'),
});


function InformesAsisteRN() {
    const { service } = useService();
    const credentials = useRecoilValue(userCredentials);
    const setSnackBar = useSetRecoilState(snackbarData);
    const setRoute = useSetRecoilState(routeInfo);
    const [despachantes, setDespachantes] = useState([]);
    const [beneficiarios, setBeneficiarios] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [articulosFilter, setArticulosFilter] = useState([]);
    const [rubros, setRubros] = useState([]);
    const [excel, setExcel] = useState([]);



    useEffect(() => {
        Promise.all([getDespachantes(), getBeneficiario(), getArticulos()]);
        setRoute({
            wordKey: `Informes - ${service.servicioNombre}`
        })
    }, [])


    const formik = useFormik({
        initialValues: {
            despachante: { label: "Todos", value: null },
            beneficiario: { label: "Todos", value: null },
            articulo: { label: "Todos", value: null },
            fechadesde: moment().format('YYYY-MM-DD'),
            fechahasta: moment().format('YYYY-MM-DD'),
            rubroId: { label: "Todos", value: null },
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const excel = await generateExcel(values);
            exportToExcel(excel)
        },
    });


    const generateExcel = async (values) => {
        const data = {
            despachanteId: values.despachante.value,
            beneficiarioMSUId: values.beneficiario.value,
            rubroId: values.rubroId.value,
            articuloId: values.articulo.value,
            fechaDesde: values.fechadesde,
            fechaHasta: values.fechahasta,
        }
        const response = await RNESInformeGet(credentials, data);

        if (response.length === 0) {
            setSnackBar({ message: "No hay movimientos disponibles para los parámetros seleccionados", severity: "warning", open: true });
            return
        } else {
            const mapResponse = response.map((e) => {
                return {
                    "Fecha": e.rnesMovimientoFH,
                    "Rubro": e.rnesRubroNombre,
                    "Artículo": e.rnesArticuloNombre,
                    "Cantidad": e.rnesMovimientoCantidad,
                    "Beneficiario": `${e.rnBeneficiarioNombre} (${e.rnBeneficiarioDNI})`,
                    "Despachante": `${e.rnDespachanteNombre} (${e.rnDespachanteDNI})`,
                }
            })
            return mapResponse;
        }

    }

    const getDespachantes = async (page, pageSize) => {
        try {
            const data = {
                rolCod: null,
                servicioHooliCod: service.servicioCod,
                page: page + 1,
                pageSize,
                usuarioTieneApp: null,
                usuarioNroDeDocumentoX: null,
                usuarioApellidos: null,
                merchantServicioUsuarioStatus: null,
                fechaDesde: null,
                fechaHasta: null,
                fechaTipo: null,
            }

            const response = await RNDespachanteGet(credentials, data);
            const mapResponse = response.result.map((des) => {

                return {
                    label: `${des.usuarioNombreCompleto === null ? " " : des.usuarioNombreCompleto} (${des.usuarioNroDeDocumentoX})`,
                    value: des.usuarioId
                }
            })
            setDespachantes([{ label: "Todos", value: null }, ...mapResponse]);

        } catch (e) {
            console.log("Error", e);
        }
    }

    const getBeneficiario = async (page, pageSize) => {
        try {
            const data = {
                rolCod: null,
                servicioHooliCod: service.servicioCod,
                page: page + 1,
                pageSize,
                usuarioTieneApp: null,
                usuarioNroDeDocumentoX: null,
                usuarioApellidos: null,
                merchantServicioUsuarioStatus: null,
                fechaDesde: null,
                fechaHasta: null,
                fechaTipo: null,
            }

            const response = await RNBeneficiarioGet(credentials, data);
            const mapResponse = response.result.map((des) => {
                return {
                    label: `${des.usuarioNombreCompleto ? des.usuarioNombreCompleto : ""} (${des.usuarioNroDeDocumentoX})`,
                    value: des.merchantServicioUsuarioId
                }
            })
            setBeneficiarios([{ label: "Todos", value: null }, ...mapResponse]);

        } catch (e) {
            console.log("Error", e);
        }
    }

    const getArticulos = async () => {
        try {
            const rubrosResponse = await RNESRubrosGet(credentials);
            const response = await RNESArticulosGet(credentials);
            const mapRubros = rubrosResponse.map((des) => {
                return {
                    label: `${des.RNESRubroNombre}`,
                    value: des.RNESRubroId
                }
            })
            const mapResponse = response.map((des) => {
                return {
                    label: `${des.RNESArticuloNombre}`,
                    value: des.RNESArticuloId,
                    rubro: des.RNESRubroId
                }
            })
            setRubros([{ value: 0, label: "Todos" }, ...mapRubros]);
            setArticulos([{ label: "Todos", value: null }, ...mapResponse]);
            setArticulosFilter([{ label: "Todos", value: null, rubro: 0 }, ...mapResponse]);
            console.log(response)

        } catch (e) {
            console.log("Error", e);
        }
    }
    console.log(formik.values)
    const exportToExcel = (arr) => {
        const worksheet = XLSX.utils.json_to_sheet(arr);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `informe-${moment().format('DD-MM-YYYY HH-mm-ss')}.xlsx`);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container width={"100vw"} height={"100vh"} display={"flex"} alignItems={"center"} flexDirection={"column"} rowSpacing={2} mt={2}>
                <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                    <Autocomplete
                        value={formik.values.despachante}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('despachante', newValue);
                        }}
                        options={despachantes}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar despachante"
                                variant="standard"
                                error={formik.touched.despachante && Boolean(formik.errors.despachante)}
                                helperText={formik.touched.despachante && formik.errors.despachante}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                    <Autocomplete
                        value={formik.values.beneficiario}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('beneficiario', newValue);
                        }}
                        options={beneficiarios}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar beneficiario"
                                variant="standard"
                                error={formik.touched.beneficiario && Boolean(formik.errors.beneficiario)}
                                helperText={formik.touched.beneficiario && formik.errors.beneficiario}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                    <Autocomplete
                        value={formik.values.rubroId}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('rubroId', newValue);
                            if (newValue.value === 0 || newValue.value === null) {
                                formik.setFieldValue("articulo", { label: 'Todos', value: null })
                            }
                            const filter = articulos.filter(articulo => articulo.rubro === newValue.value);
                            setArticulosFilter([{ label: "Todos", value: null, rubro: 0 }, ...filter]);
                        }}
                        options={rubros}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar rubro"
                                variant="standard"
                                error={formik.touched.articulo && Boolean(formik.errors.articulo)}
                                helperText={formik.touched.articulo && formik.errors.articulo}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                    <Autocomplete
                        value={formik.values.articulo}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('articulo', newValue);
                        }}
                        disabled={formik?.values?.rubroId.value === null || formik?.values?.rubroId.value === 0 ? true : false}
                        options={articulosFilter}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar articulo"
                                variant="standard"
                                error={formik.touched.articulo && Boolean(formik.errors.articulo)}
                                helperText={formik.touched.articulo && formik.errors.articulo}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                    <Box display={"flex"} gap={4}>
                        <FormikDateInput
                            name={"fechadesde"}
                            formik={formik}
                            data={{ fechadesde: { label: "Fecha desde", placeholder: "desde" } }}
                        />
                        <FormikDateInput
                            name={"fechahasta"}
                            formik={formik}
                            data={{ fechahasta: { label: "Fecha hasta", placeholder: "desde" } }}
                        />
                    </Box>

                </Grid>

                <Grid item md={6} lg={6} width={"50vw"} maxHeight={"100px"}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Generar y descargar excel
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default InformesAsisteRN
