import React, { useEffect, useRef, useState } from "react";

import { FormButton } from "components";
import { FormikDateInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionUpd } from "services/services-mia/RNConnectionUpd";
import { RNESRubroUpd } from "services/services-mia/RNESRubroUpd";
import { RNESRubrosGet } from "services/services-mia/RNESRubrosGet";
import { RNPSProgramaGet } from "services/services-mia/RNPSProgramaGet";
import { RNPSProgramaUpd } from "services/services-mia/RNPSProgramaUpd";

const estados = [
    { estadoCod: "P", estadoNombre: "Pendiente" },
    { estadoCod: "A", estadoNombre: "Activo" },
    { estadoCod: "B", estadoNombre: "Baja" },
    { estadoCod: "S", estadoNombre: "Suspendido" },
    { estadoCod: "X", estadoNombre: "Cancelado" },
    { estadoCod: "R", estadoNombre: "Rechazado" },
];

const estadosTransiciones = {
    P: ["P", "X"],
    A: ["A", "B", "S"],
    B: ["B"],
    S: ["S", "A"],
    X: ["X", "P"],
    R: ["R", "B"],
};

const emailRegex =
    /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);
    const dataFormik = {
        inicio: { label: "Fecha de inicio" },
        fin: { label: "Fecha de fin" },
    }
    const getProgramaData = async () => {
        setIsLoading(true);
        const result = await RNPSProgramaGet(credentials, { programaId: user.id })
        // console.log(result);
        formikRef.current.setValues({
            id: result[0].rnpsProgramaId,
            nombre: result[0].rnpsProgramaNombre,
            cod: result[0].rnpsProgramaCodigo,
            inicio: result[0].rnpsProgramaFechaInicio,
            fin: result[0].rnpsProgramaFechaFin,
            version: result[0].rnpsProgramaVersion,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await RNPSProgramaUpd(credentials, values)
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Programa modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getProgramaData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                cod: "",
                inicio: "",
                fin: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe ingresar un codigo").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un codigo"),
                    inicio: yup.string("Debe ingresar una fecha de inicio").required("Debe ingresar una fecha de inicio"),
                    fin: yup.string("Debe ingresar una fecha de fin").required("Debe ingresar una fecha de fin"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del programa"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            placeholder=""
                            labelText="Código"
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="inicio"
                            labelText="Fecha de inicio"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("inicio", e.target.value);
                            }}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fin"
                            labelText="Fecha de fin"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fin", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Modificar" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
