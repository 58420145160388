import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";
import {
    userInfo,
    userCredentials,
    routeInfo,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";
import { logout } from "services/Local-services";

import { ListDrawer } from "components";

import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";

import LogoutIcon from "assets/icons/icon-logout.svg";
import { LogoMIAFooter, OCPLogo, UserPlaceholder } from "assets/icons";

import styles from "./Header.module.scss"

export default function Header() {
    const [openDrawer, setOpenDrawer] = useState(false);

    const [user, setUser] = useRecoilState(userInfo);
    const credentials = useRecoilValue(userCredentials);
    const route = useRecoilValue(routeInfo);
    const location = useLocation();

    const handleDrawerOpen = async () => {
        setOpenDrawer(!openDrawer);
    };
    useEffect(() => {
        console.log(route)
    }, [window.location])
    console.log(route.wordKey)
    return (
        <>
            <AppBar position="relative" className={styles.root}>
                <Toolbar
                    variant="dense"
                    className={styles.toolbar}
                >
                    <div className={styles.menu}>
                        <IconButton
                            edge="start"
                            aria-label="menu"
                            color="inherit"
                            onClick={handleDrawerOpen}
                        >
                            <GridMenuIcon className={styles.menuIcon} />
                        </IconButton>

                        <Typography
                            className={styles.wordKey}
                            color="inherit"
                            variant="h4"
                        >
                            {route?.wordKey
                                ? route.wordKey === "Usuarios - Programa Asiste RN" ? "Administrativos - Programa Asiste RN" : route.wordKey
                                : (Object.values(Routes).find(route => route.route === location.pathname))?.wordKey}
                        </Typography>
                    </div>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"33%"} >
                        <Typography
                            className={styles.merchantLogo}
                            color="inherit"
                            variant="h4"
                        >

                            {credentials.merchantNombre}
                        </Typography>
                    </Box>


                    <div className={styles.userData}>
                        <p>{user?.usuarioNombres} {" "} {user?.usuarioApellidos}</p>
                        <img src={user?.imagePathPERFIL} alt="" onError={() => setUser((data) => ({ ...data, imagePathPERFIL: UserPlaceholder }))} />
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                className={styles.drawer}
                variant="persistent"
                anchor="left"
                open={openDrawer}
            >
                <div className={styles.drawerHeader}>
                    <img
                        src={user?.imagePathLOGO}
                        alt="Logo"
                        onError={() => setUser((data) => ({ ...data }))}
                        className={styles.logo}
                    />
                </div>
                <div className={styles.listContainer}>
                    <ListDrawer setOpen={setOpenDrawer} />
                </div>
                <div className={styles.logout} onClick={logout}>
                    <img src={LogoutIcon} alt="Logout" />
                    Cerrar Sesión
                </div>
                <div className={styles.drawerFooter}>
                    <div>
                        Powered by <img src={LogoMIAFooter} alt="" />
                    </div>
                    <p>Versión 1.0.7</p>
                </div>
            </Drawer>
            {openDrawer && (
                <div
                    className={styles.drawerHandler}
                    onClick={() => setOpenDrawer(false)}
                />
            )}
        </>
    );
}
