import axiosPost from "./axiosPost";

export const RNESAsignacionIns = async (credentials, data) => {
    const body = {
        service: "RNESAsignacionIns",
        params: {
            rnesAsignacionMSUId: data?.usuarioMerchantId,
            rnesArticuloId: data?.articuloId,
            rnesMovimientoLatitud: data?.latitud,
            rnesMovimientoLongitud: data?.longitud,
            rnesAsignacionFechaDisponible: data?.fecha,
            rnesAsignacionCantidad: data?.cant,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
