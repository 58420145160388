import axiosPost from "./axiosPost";

export const RNESArticuloIns = async (credentials, data) => {
    const body = {
        service: "RNESArticuloIns",
        params: {
            rnesRubroId: data?.rubro,
            rnesArticuloMaximo: data?.cant,
            rnesArticuloNombre: data?.nombre,
            rnesArticuloNombrePlural: data?.plural,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
