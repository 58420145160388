import axiosPost from "./axiosPost";

export const RNPSProgramaIns = async (credentials, data) => {
    const body = {
        service: "RNPSProgramaIns",
        params: {
            rnpsProgramaCodigo: data?.cod,
            rnpsProgramaNombre: data?.nombre,
            rnpsProgramaFechaFin: data?.fin,
            rnpsProgramaFechaInicio: data?.inicio,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
