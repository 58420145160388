import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularIndeterminate, FormButton } from "components";
import { FormikDateInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";
import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";
import { RNESRubroIns } from "services/services-mia/RNESRubroIns";
import { RNPSProgramaIns } from "services/services-mia/RNPSProgramaIns";

export default function InsertModal({ updateList, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const formikRef = useRef();

    const dataFormik = {
        inicio: { label: "Fecha de inicio" },
        fin: { label: "Fecha de fin" },
    }

    const handleCreatePrograma = async (values) => {
        const response = await RNPSProgramaIns(
            credentials,
            values
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Programa creado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                cod: "",
                inicio: "",
                fin: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe ingresar un codigo").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un codigo"),
                    inicio: yup.string("Debe ingresar una fecha de inicio").required("Debe ingresar una fecha de inicio"),
                    fin: yup.string("Debe ingresar una fecha de fin").required("Debe ingresar una fecha de fin"),
                })
            }
            onSubmit={(values) => {
                handleCreatePrograma(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del programa"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            placeholder=""
                            labelText="Código"
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="inicio"
                            labelText="Fecha de inicio"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("inicio", e.target.value);
                            }}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fin"
                            labelText="Fecha de fin"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fin", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
