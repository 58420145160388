import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { connectionIns, usuarioBasicosGet } from "services/services-mia";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];



export default function InsertModal({ updateList, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const [placeholderDNI, setPlaceholderDNI] = useState('')
    const [regexDNI, setRegexDNI] = useState(/./)

    const formikRef = useRef();

    const functions = {
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };
    const handleConnection = async (values) => {
        const response = await RNConnectionIns(
            {
                servicioHooliCod: "RNES",
                rolCod: "BEN",
                usuarioNroDeDocumentoX: values.dni,
                usuarioSexo: values.genero,
                usuarioMail: null,
                usuarioCelular: null,
                name: values.name,
                lastName: values.lastName,
            },
            credentials
        );
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Beneficiario creada correctamente",
                severity: "success",
                open: true,
            });
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                dni: "",
                genero: sexos[0].sexoCod,
                name: "",
                lastName: "",
                rol: "BEN",
            }}
            validationSchema={
                yup.object({
                    dni: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .matches(regexDNI, { message: "Número de documento inválido" }),
                })
            }
            onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="name"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="lastName"
                            labelText="Apellido"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="dni"
                            placeholder={placeholderDNI}
                            labelText="Número de DNI"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="genero"
                            labelText="Género"
                        >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                    key={sexoCod}
                                    data-id={sexoCod}
                                    value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>

                    </>



                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
