import axiosPost from "./axiosPost";

export const RNESArticuloUpd = async (credentials, data) => {
    const body = {
        service: "RNESArticuloUpd",
        params: {
            rnesRubroId: data?.rubro,
            rnesArticuloId: data?.id,
            rnesArticuloMaximo: data?.cant,
            rnesArticuloNombre: data?.name,
            rnesArticuloNombrePlural: data?.plural,
            rnesArticuloVersion: data?.version,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
