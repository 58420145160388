import React, { useEffect, useRef, useState } from "react";

import { FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionUpd } from "services/services-mia/RNConnectionUpd";
import { RNBeneficiarioGet } from "services/services-mia/RNBeneficiarioGet";

const estados = [
    { estadoCod: "P", estadoNombre: "Pendiente" },
    { estadoCod: "A", estadoNombre: "Activo" },
    { estadoCod: "B", estadoNombre: "Baja" },
];

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);
    const getUserData = async () => {
        setIsLoading(true);
        const response = await RNBeneficiarioGet(
            credentials,
            {
                usuarioNroDeDocumentoX: user.documento,
                servicioHooliCod: user.servicioCod
            }
        );
        setData(response?.result[0]);
        formikRef.current.setFieldValue("estado", response?.result[0].relacionStatusCod);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await RNConnectionUpd({
            rolCod: "USU",
            merchantServicioUsuarioId: data.merchantServicioUsuarioId,
            merchantServicioUsuarioStatus: values.estado
        }, credentials)
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Programa modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getUserData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                estado: ""
            }}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="estado"
                            labelText={"Estado"}
                        >
                            {estados.map(({ estadoCod, estadoNombre }) => (
                                <option
                                    key={estadoCod}
                                    data-id={estadoCod}
                                    value={estadoCod}
                                >
                                    {estadoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Modificar" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
