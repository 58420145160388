import axiosPost from "./axiosPost";

export const clienteUsuarioGet = async ({
    merchantId,
    tipoRelacion,
    usuarioId = null,
    servicioCod = null,
    page = null,
    pageSize = null,
    usuarioTieneApp = null,
    usuarioNroDeDocumentoX = null,
    usuarioApellidos = null,
    rolCod = null,
    fechaDesde = null,
    fechaHasta = null,
    estado = null,
    fechaTipo = 'ESTADO',
}, credentials) => {
    const body = {
        service: "MIADemoClienteUsuarioGet",
        params: {
            merchantId: credentials?.merchantId ?? merchantId,
            merchantServicioUsuarioTiporel: tipoRelacion,
            merchantServicioUsuarioStatus: estado,
            usuarioId,
            servicioHooliCod: servicioCod,
            usuarioTieneApp,
            page,
            pageSize,
            usuarioNroDeDocumentoX,
            usuarioApellidos,
            rolCod,
            fechaDesde,
            fechaHasta,
            fechaTipo
        },
    };
    const result = await axiosPost(body, credentials);

    if (page !== null && pageSize !== null) {
        return {
            result: result.result,
            total: result.status.id,
            status: result.status,
        };
    }
    return result.result;
};
