import React, { useEffect, useRef, useState } from "react";

import { FormButton } from "components";
import { FormikNumberInput, FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { RNESArticulosGet } from "services/services-mia/RNESArticulosGet";
import { RNESArticuloUpd } from "services/services-mia/RNESArticuloUpd";

export default function UpdateModal({ updateList, user, closeModal, filtro }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [rubros, setRubros] = useState(filtro);
    const credentials = useRecoilValue(userCredentials);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);
    const getArticulosData = async () => {
        setIsLoading(true);
        const result = await RNESArticulosGet(credentials, { articuloId: user.id })
        formikRef.current.setValues({
            id: result[0].RNESArticuloId,
            nombre: result[0].RNESArticuloNombre,
            rubro: result[0].RNESRubroId,
            plural: result[0].RNESArticuloNombrePlural,
            cant: result[0].RNESArticuloMaximo,
            version: result[0].RNESArticuloVersion,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleUpdArticulo = async (values) => {
        const response = await RNESArticuloUpd(credentials, {
            id: data.RNESArticuloId,
            name: values.nombre,
            version: data.RNESArticuloVersion,
            plural: values.plural,
            cant: values.cant,
            rubro: values.rubro,
        })
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Articulo modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getArticulosData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                rubro: "",
                plural: "",
                cant: ""
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    rubro: yup.string("Debe seleccionar un rubro").required("Debe ingresar un rubro"),
                    plural: yup.string("Debe ingresar un plural").required("Debe ingresar un plural"),
                    cant: yup.number("Debe ingresar una cantidad").max(99, "Debe ser menor a 100").min(1, "Debe ser mayor a 0").required("Debe ingresar una cantidad"),
                })
            }
            onSubmit={(values) => {
                handleUpdArticulo(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="rubro"
                            labelText="Rubro"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("rubro", e.target.value);
                            }}
                        >
                            <option value="" selected disabled >Seleccione</option>
                            {rubros.map(({ RNESRubroId, RNESRubroNombre }) => (
                                <option
                                    key={RNESRubroId}
                                    data-id={RNESRubroId}
                                    value={RNESRubroId}
                                >
                                    {RNESRubroNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del artículo"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="plural"
                            placeholder=""
                            labelText="Plural del artículo"
                        />
                        <FormikNumberInput
                            fullWidth={true}
                            name="cant"
                            placeholder=""
                            labelText="Cantidad máxima"
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
