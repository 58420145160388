import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";
import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";
import { RNESRubroIns } from "services/services-mia/RNESRubroIns";

export default function InsertModal({ updateList, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const formikRef = useRef();

    const handleCreateRubro = async (values) => {
        const response = await RNESRubroIns(
            credentials,
            { name: values.nombre }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Rubro creado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                })
            }
            onSubmit={(values) => {
                handleCreateRubro(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del rubro"
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
