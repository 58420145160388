import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNCAAsignacionDo } from "services/services-mia/RNCAAsignacionDo";

export default function AsignarGarrafas({ updateList, servicio, closeModal, user }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [coords, setCoords] = useState({ latitud: null, longitud: null })

    const [inRequest, setInRequest] = useState(false);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoords({ latitud: data.coords.latitude.toFixed(6), longitud: data.coords.longitude.toFixed(6) })
            }
        );
    }, [])

    const formikRef = useRef();

    const functions = {
        setSnackbarInfo,
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    }

    const handleConnection = async (values, resetForm, setFieldValue) => {
        setInRequest(true);

        const data = {
            ...values,
            rncaMovimientoLatitud: coords.latitud,
            rncaMovimientoLongitud: coords.longitud
        }

        const responseIns = await RNCAAsignacionDo(
            data,
            credentials
        );

        handleResponse(
            responseIns,
            "Conexión creada exitosamente",
            functions
        );
        resetForm()



        setInRequest(false);
        return;
    };



    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                usuarioNroDeDocumento: user.documento,
                usuarioSexo: user.sexo,
                rncaMovimientoCantidad: "",
                rncaMovimientoStatusFecha: "",

            }}
            validationSchema={
                yup.object({
                    rncaMovimientoCantidad: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required, "Ingrese una cantidad")

                })
            }
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {

                try {

                    await handleConnection(values, resetForm, setFieldValue);


                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>

                    <FormikTextInput
                        fullWidth={true}
                        name="rncaMovimientoCantidad"
                        labelText="Cantidad de garrafas"
                    />

                    <FormikTextInput
                        name={"rncaMovimientoStatusFecha"}
                        labelText={"Disponible desde"}
                        type={"date"}

                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
