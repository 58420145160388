import axiosPost from "./axiosPost";

export const RNConnectionUpd = async (data, credentials) => {
    const {
        merchantServicioUsuarioId,
        rolCod,
        merchantServicioUsuarioStatus,
        conexionUUID,
        merchantServicioUsuarioMail,
    } = data;
    const body = {
        service: "RNConnectionUpd",
        params: {
            merchantServicioUsuarioId,
            rolCod,
            merchantServicioUsuarioStatus,
            merchantServicioUsuarioMail,
            conexionUUID,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
