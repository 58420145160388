import React, { useRef, useState } from "react";
import { CircularIndeterminate, FormButton } from "components";
import { FormikNumberInput, FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";
import styles from "styles/pages/ModalActions.module.scss";
import { RNESArticuloIns } from "services/services-mia/RNESArticuloIns";

export default function InsertModal({ updateList, servicio, closeModal, filtro }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const [rubros, setRubros] = useState(filtro);
    const formikRef = useRef();

    const handleCreateArticulo = async (values) => {
        const response = await RNESArticuloIns(
            credentials,
            { rubro: values.rubro, cant: values.cant, nombre: values.nombre, plural: values.plural },
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Articulo creado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                rubro: "",
                plural: "",
                cant: ""
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    rubro: yup.string("Debe seleccionar un rubro").required("Debe ingresar un rubro"),
                    plural: yup.string("Debe ingresar un plural").required("Debe ingresar un plural"),
                    cant: yup.number("Debe ingresar una cantidad").max(99, "Debe ser menor a 100").min(1, "Debe ser mayor a 0").required("Debe ingresar una cantidad"),
                })
            }
            onSubmit={(values) => {
                handleCreateArticulo(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="rubro"
                            labelText="Rubro"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("rubro", e.target.value);
                            }}
                        >
                            <option value="" selected disabled >Seleccione</option>
                            {rubros.map(({ RNESRubroId, RNESRubroNombre }) => (
                                <option
                                    key={RNESRubroId}
                                    data-id={RNESRubroId}
                                    value={RNESRubroId}
                                >
                                    {RNESRubroNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del artículo"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="plural"
                            placeholder=""
                            labelText="Plural del artículo"
                        />
                        <FormikNumberInput
                            fullWidth={true}
                            name="cant"
                            placeholder=""
                            labelText="Cantidad máxima"
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
