import React, { useEffect, useRef, useState } from "react";

import { Buttons, CircularIndeterminate, FormButton, HeaderButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik, useFormik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/Pages.module.scss"
import XGrid from "components/UI/Grid/XGrid";
import { asignacionNavidadTitles as titles } from 'consts/titulos-de-tablas';
import { Add, Cached, EditOutlined } from "@mui/icons-material";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ACTIONS } from "consts/Actions";
import Modal from "components/UI/Modal/Modal";
import { InsertModal } from "./AsignacionModales/InsertModal";
import { UpdateModal } from "./AsignacionModales/UpdateModal";
import DeleteModal from "./AsignacionModales/DeleteModal";
import { RNPSAsignacionesGet } from "services/services-mia/RNPSAsignacionesGet";
import moment from "moment";
import Filters from "components/Filters";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { transformNumber } from "services/Local-services";

const rubros = [
    { id: 1, name: 'Alimentos' }
]

export default function AsignarGarrafas({ updateList, servicio, closeModal, user }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [coords, setCoords] = useState({ latitud: null, longitud: null })
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [inRequest, setInRequest] = useState(false);
    const [rowSelectedSecond, setRowSelectedSecond] = useState({ status: false, row: {} });
    const [asignaciones, setAsignaciones] = useState([]);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            rubro: 1
        },
        onSubmit: () => {
            getAsigncaiones()
        },
    })
    const resetForm = () => {
        formik.setFieldValue("rubro", "");
        getAsigncaiones()
    };

    const getAsigncaiones = async () => {
        setAsignaciones([]);
        setLoading(true);
        const result = await RNPSAsignacionesGet(credentials, { msuId: user.id });
        const newRows = result[0]?.rnpsAsignacionId ? result?.map((data) => {
            return {
                id: data?.rnpsAsignacionId,
                rubro: "Alimentos",
                fecha: data?.rnpsAsignacionFecha ? moment(data?.rnpsAsignacionFecha).format("DD/MM/YYYY").toString() : null,
                programa: data?.rnpsProgramaNombre,
                cant: `${data?.rnpsAsignacionDC === "C" ? "-" : ""}${transformNumber(data?.rnpsAsignacionMonto)}`,
                fechaAsignacion: data?.rnpsAsignacionUFH ? moment(data?.rnpsAsignacionUFH).format("DD/MM/YYYY HH:mm:ss").toString() : null,
                asignado: `${data.usuarioNombre}`,
                esReversible: data?.rnpsAsignacionEsReversible,
                esReversibleRender: data?.rnpsAsignacionEsReversible === "N" ? "No" : "Si",
                movimientoTipo: data?.rnpsAsignacionDC
            };
        }) : []
        setAsignaciones(newRows);
        setLoading(false);
    }
    const updateSecondList = () => {
        getAsigncaiones();
        setRowSelectedSecond({ status: false, row: {} });
    };
    const closeSecondModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoords({ latitud: data.coords.latitude.toFixed(6), longitud: data.coords.longitude.toFixed(6) })
            }
        );
        getAsigncaiones();
    }, [])
    console.log(rowSelectedSecond)
    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} >
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        user={user}
                        // roles={connectionRoles}
                        updateList={updateSecondList}
                        closeModal={closeSecondModal}
                        coords={coords}
                    />
                )}
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={user}
                        row={rowSelectedSecond.row}
                        // roles={connectionRoles}
                        updateList={updateSecondList}
                        closeModal={closeSecondModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={user}
                        row={rowSelectedSecond.row}
                        // roles={connectionRoles}
                        updateList={updateSecondList}
                        closeModal={closeSecondModal}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "rubro", label: "Rubro", disabled: false, options: rubros, firtsOptionKey: "id", firstOptionName: "name", firstOptionValue: "id", selectDefault: 0 }
                            ]
                        }
                        disabled={loading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={loading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { getAsigncaiones() }}
                        disabled={rowSelectedSecond.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear asignación"}
                        handleClick={handleClick}
                        data={{
                            title: `Crear asignación para ${user.fullName || user.document}`,
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelectedSecond.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    {/* <HeaderButton
                        text={"Modificar asignación"}
                        handleClick={handleClick}
                        data={{
                            title: `Modificar asignación para ${user.fullName || user.document}`,
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelectedSecond.row.id || rowSelectedSecond.row.cantDisponible !== 0}
                    >
                        <EditOutlined />
                    </HeaderButton> */}
                    <HeaderButton
                        text={"Eliminar asignación"}
                        handleClick={handleClick}
                        data={{
                            title: `Eliminar asignación ${user.fullName || user.document}`,
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelectedSecond.row.id || rowSelectedSecond.row.esReversible === "N"}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                </Buttons>
            </div>
            <XGrid rows={asignaciones} titles={titles} loading={loading} secondarySelected={rowSelectedSecond} setSecondarySelected={setRowSelectedSecond} />
        </main>
    );
}
