import React, { useState } from "react";

import { FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionUpd } from "services/services-mia/RNConnectionUpd";

const estados = [
    { estadoCod: "P", estadoNombre: "Pendiente" },
    { estadoCod: "A", estadoNombre: "Activo" },
    { estadoCod: "B", estadoNombre: "Baja" },
    { estadoCod: "S", estadoNombre: "Suspendido" },
    { estadoCod: "X", estadoNombre: "Cancelado" },
    { estadoCod: "R", estadoNombre: "Rechazado" },
];

const estadosTransiciones = {
    P: ["P", "X"],
    A: ["A", "B", "S"],
    B: ["B"],
    S: ["S", "A"],
    X: ["X", "P"],
    R: ["R", "B"],
};

const emailRegex =
    /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);
    const handleSubmit = async ({
        estado,
    }) => {
        setInRequest(true);
        const data = {
            merchantServicioUsuarioId: user.id,
            rolCod: user.rolCod,
            merchantServicioUsuarioStatus: estado,
            conexionUUID: null,
            merchantServicioUsuarioMail: null,
        }
        const response = await RNConnectionUpd(
            data,
            credentials
        );

        const functions = {
            setSnackbarInfo,
            setInRequest,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            response,
            "Conexión modificada exitosamente",
            functions
        );
    };

    const estadosFiltrados = user.estadoCod
        ? estados.filter((e) =>
            estadosTransiciones[user.estadoCod].includes(e.estadoCod)
        )
        : [];

    return (
        <Formik
            initialValues={{
                estado: user.estadoCod,

            }}
            validationSchema={
                yup.object({

                    estado: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .oneOf(estadosFiltrados.map((estado) => estado.estadoCod)),

                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>


                    <FormikSelectInput
                        fullWidth={true}
                        name="estado"
                        labelText={"Estado"}
                    >
                        {estadosFiltrados.map(({ estadoCod, estadoNombre }) => (
                            <option
                                key={estadoCod}
                                data-id={estadoCod}
                                value={estadoCod}
                            >
                                {estadoNombre}
                            </option>
                        ))}
                    </FormikSelectInput>


                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
