import React from "react";
import { useCallback, useEffect, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import { routeInfo, rowSelected } from "recoilState/GlobalState";

import { generateColumns } from "services/Local-services";

import { DataGridPro as DataGrid, DataGridPro, esES, LicenseInfo } from "@mui/x-data-grid-pro";
// import { DataGrid } from "@mui/x-data-grid";

import { LICENSE_KEY } from "consts/env";

import classes from "./XGrid.module.scss";

LicenseInfo.setLicenseKey(LICENSE_KEY);

export default function XGridServer({
    titles,
    updateList = async () => { },
    onPageChange = () => { },
    onPageSizeChange = () => { },
    onNumberOfPagesChange = () => { },
    forceReset = false,
    forceUpload = false,
    loading,
    secondarySelected,
    setSecondarySelected,
}) {
    const [isLoading, setIsLoading] = useState(loading);
    const [rows, setRows] = useState([]);
    const [pages, setPages] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [reset, setReset] = useState(false);
    const [upload, setUpload] = useState(false);

    const { route } = useRecoilValue(routeInfo);
    const [selected, setSelected] = useRecoilState(rowSelected);

    const { row } = secondarySelected ? secondarySelected : selected;
    const setter = setSecondarySelected ? setSecondarySelected : setSelected;

    const getList = useCallback(async (newPage = 0, force = false) => {
        if (!force && pages[newPage] && newPage !== numberOfPages) {
            return setRows(pages[newPage]);
        }
        setIsLoading(true);
        // console.log("rows", rows);
        const { rows: newRows, rowCount: newRowCount } = await updateList(
            newPage,
            pageSize
        );

        // if (newRows.length > 0) {
        //     setIsLoading(false);
        //     return;
        // };
        setRows(newRows);

        const newPages = [...pages];
        newPages[newPage] = newRows;
        setPages(newPages);

        setRowCount(newRowCount);
        setIsLoading(false);
    }, [numberOfPages, pageSize, pages, updateList]);

    useEffect(() => {
        if (pageSize === 0 || rows.length > 0 || loading) return;
        getList();
    }, [pageSize, rows, loading, getList]);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (forceUpload !== upload) {
            getList(page, true);
            setUpload(forceUpload);
        }
        if (forceReset !== reset) {
            setPage(0);
            setPages([]);
            getList(0, true);
            setReset(forceReset);
        };
    }, [forceUpload, getList, page, upload, forceReset, reset]);

    const columns = generateColumns(titles, route);

    return (
        <DataGridPro
            columns={columns}
            rows={rows ?? []}
            rowCount={rowCount ?? 0}
            count={rowCount ?? 0}
            loading={isLoading}
            onRowClick={(data) => {
                if (data.row.id === row?.id) {
                    setter({ status: false, row: {} })
                    return;
                }
                setter({ status: true, row: data.row })
            }}
            selectionModel={row?.id || []}
            pagination
            page={page}
            // pageSize={pageSize}
            autoPageSize
            paginationMode="server"
            onPageChange={(newPage) => {
                setPage(newPage);
                onPageChange(newPage);
                getList(newPage);
            }}
            onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
                onPageSizeChange(newPageSize);
            }}
            onStateChange={(state) => {
                const newNumberOfPages = state.pagination.pageCount + 1;
                if (newNumberOfPages !== numberOfPages) {
                    setNumberOfPages(newNumberOfPages);
                    onNumberOfPagesChange(newNumberOfPages);
                }
            }}
            className={classes.root}
            hideFooterSelectedRowCount
            disableMultipleSelection
            rowHeight={36}
            disableColumnMenu
            unstable_dataSourceCache={0}
            localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: "Sin datos" }}
        />
    );
}
