import axiosPost from "./axiosPost";

export const RNPSServicioGet = async (credentials) => {
    const body = {
        service: "RNPSServicioGet",
        params: {},
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
