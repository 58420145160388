import React, { useState, useEffect, useCallback } from "react";
import XGridServer from "components/UI/Grid/XGridServer";
import { useFormik } from "formik";
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, ResumenModal, AsignarGarrafasModal } from "./Actions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { RNBeneficiarioGet } from "services/services-mia/RNBeneficiarioGet";
import { HeaderButton } from "components";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import EditOutlined from "@mui/icons-material/EditOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Buttons } from "components";
import Filters from "components/Filters";
import { ACTIONS } from "consts/Actions";
import moment from "moment";
import { usuariosXServicioNavidad as titles } from "consts/titulos-de-tablas";
import styles from "styles/pages/Pages.module.scss";
import { useService } from "components/route/ServiceRoute";
import DetailModal from "./Actions/DetailModal";
import { connectionRolGet, rolGet } from "services/services-mia";
import Timeline from "@mui/icons-material/Timeline";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DeleteModal from "./Actions/DeleteModal";

const onboardStatus = [
    {
        onboardStatusCod: 'Y',
        onboardStatusNombre: 'Sí'
    },
    {
        onboardStatusCod: 'N',
        onboardStatusNombre: 'No'
    }
];

const billetera = [
    {
        billeteraCod: 'Y',
        billeteraNombre: 'Si'
    },
    {
        billeteraCod: 'N',
        billeteraNombre: 'No'
    }
]
const genderName = {
    "M": "Masculino",
    "F": "Femenino",
    "X": "No binario"
}

export default function BeneficiariosNavidad() {
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    const [editable, setEditable] = useState(false);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [roles, setRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [resetTable, setResetTable] = useState(false);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { service } = useService();
    const formik = useFormik({
        initialValues: {
            usuarioApellidos: "",
            usuarioNumeroDeDocumentoX: "",
            rolCod: "",
            merchantServicioUsuarioStatus: "",
            fechaDesde: moment().date(),
            fechaHasta: moment().date(),
            billetera: "",
            asignaciones: "",
            // consumos: ""
        },
        onSubmit: () => {
            setResetTable((value) => !value);
        },
    })
    const resetForm = () => {
        formik.setFieldValue("usuarioApellidos", "");
        formik.setFieldValue("usuarioNumeroDeDocumentoX", "");
        formik.setFieldValue("rolCod", "");
        formik.setFieldValue("merchantServicioUsuarioStatus", "");
        formik.setFieldValue("fechaDesde", moment().date());
        formik.setFieldValue("fechaHasta", moment().date());
        formik.setFieldValue("billetera", "");
        formik.setFieldValue("asignaciones", "");
        // formik.setFieldValue("consumos", "");
        setResetTable(!resetTable)
        setForceUpload(!forceUpload);
        setRowSelected({ status: false, row: {} })
        updateList()
    };
    const getBeneficiarios = async (page, pageSize) => {
        setLoading(true);
        const data = {
            rolCod: formik.values.rolCod,
            servicioHooliCod: service.servicioCod,
            page: page + 1,
            pageSize,
            usuarioTieneApp: formik.values.merchantServicioUsuarioStatus || null,
            usuarioNroDeDocumentoX: formik.values.usuarioNumeroDeDocumentoX || null,
            usuarioApellidos: formik.values.usuarioApellidos || null,
            merchantServicioUsuarioStatus: null,
            fechaDesde: null,
            fechaHasta: null,
            fechaTipo: null,
            billetera: formik.values.billetera || null,
            asignaciones: formik.values.asignaciones || null,
            // consumos: formik.values.consumos || null,
        }
        const { result, total } = await RNBeneficiarioGet(credentials, data);
        const newRows = result ? result.map((usuario) => {
            return {
                id: usuario.merchantServicioUsuarioId,
                usuarioId: usuario.usuarioId,
                nombreServicio: usuario.servicioNombre,
                servicioId: usuario.servicioId,
                estadoCod: usuario.relacionStatusCod,
                email: usuario.usuarioMail,
                rolId: usuario.rolId,
                rolCod: usuario.rolCod,
                rolNombre: usuario.rolNombre,
                sexo: usuario.usuarioSexo,
                documento: usuario.usuarioNroDeDocumentoX,
                [titles[0].field]: usuario.usuarioNombreCompleto,
                [titles[1].field]: usuario.usuarioNroDeDocumentoX,
                [titles[2].field]: genderName[usuario.usuarioSexo],
                [titles[3].field]: usuario.usuarioFechaDeNacimiento,
                [titles[4].field]: usuario.rolNombre,
                [titles[5].field]: usuario.usuarioId === null ? 'NO' : 'SI',
                [titles[6].field]: usuario.usuarioFechaDeAlta ? moment(usuario.usuarioFechaDeAlta).format("YYYY-MM-DD").toString() : null,
                [titles[7].field]: usuario.usuarioTieneBilletera === "Y" ? 'SI' : 'NO',
                [titles[8].field]: usuario.relacionStatus,
                [titles[9].field]: usuario.usuarioFecha,
                [titles[10].field]: usuario.RNPSAsignaciones,
                // [titles[11].field]: usuario.RNAsignacionConsumoConsumos,
            };
        }) : [];
        setLoading(false);
        return {
            rows: newRows,
            rowCount: total
        }

    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
        setForceUpload(!forceUpload)
    };
    const updateList = useCallback(() => {
        setRowSelected({ status: false, row: {} })
        setForceUpload((value) => !value);
    }, [])
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };


    useEffect(() => {
        setRoute({
            wordKey: `Beneficiarios - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])
    useEffect(() => {
        if (Object.keys(credentials).length > 0 && service?.servicioCod) {
            (async () => {
                let rolesFilter = null;
                const resultRoles = await rolGet(service.servicioCod, credentials);
                rolesFilter = resultRoles.result.filter((e) => e.rolCod === "BEN" || e.rolCod === "BDE");
                const connectionRoles = await connectionRolGet(service.servicioCod, credentials)
                setConnectionRoles(connectionRoles.result);
                setRoles([...rolesFilter]);
            })();
        }
    }, [credentials, service])

    useEffect(() => {
        if (rowSelected.row.id && service.servicioPermiso === 'E' && connectionRoles.some(rol => rol.rolCod === rowSelected.row.rolCod)) {
            setEditable(true);
            return;
        }
        setEditable(false);
    }, [connectionRoles, rowSelected.row.id, rowSelected.row.rolCod, service])
    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} >
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <ResumenModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        service={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <DetailModal
                        usuarioId={rowSelected.row.usuarioId}
                        service={service}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === "ASIGNAR" && (
                    <AsignarGarrafasModal
                        user={rowSelected.row}
                        service={service}
                        closeModal={closeModal}
                        updateList={updateList}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "text", name: "usuarioApellidos", label: "Apellido", disabled: false },
                                { type: "text", name: "usuarioNumeroDeDocumentoX", label: "Documento", disabled: false },
                                // { type: "select", name: "rolCod", label: "Rol", disabled: false, options: roles, firtsOptionKey: "rolCod", firstOptionName: "rolNombre", firstOptionValue: "rolCod" },
                                { type: "select", name: "merchantServicioUsuarioStatus", label: "Onboard", disabled: false, options: onboardStatus, firtsOptionKey: "onboardStatusCod", firstOptionName: "onboardStatusNombre", firstOptionValue: "onboardStatusCod" },
                                { type: "select", name: "billetera", label: "Billetera", disabled: false, options: billetera, firtsOptionKey: "billeteraCod", firstOptionName: "billeteraNombre", firstOptionValue: "billeteraCod" },
                                { type: "select", name: "asignaciones", label: "Asignaciones", disabled: false, options: billetera, firtsOptionKey: "billeteraCod", firstOptionName: "billeteraNombre", firstOptionValue: "billeteraCod" },
                                // { type: "select", name: "consumos", label: "Consumos", disabled: false, options: billetera, firtsOptionKey: "billeteraCod", firstOptionName: "billeteraNombre", firstOptionValue: "billeteraCod" }
                            ]
                        }
                        disabled={loading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={loading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver detalle"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalle del Usuario",
                            procede: ACTIONS.DETAIL
                        }}
                        disabled={!rowSelected.row.id || !rowSelected.row.usuarioId}
                    >
                        <VisibilityOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nuevo beneficiario"}
                        handleClick={handleClick}
                        data={{
                            title: "Nuevo beneficiario",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || service.servicioPermiso !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar beneficiario"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar beneficiario",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                    <HeaderButton
                        text={"Asignaciones"}
                        handleClick={handleClick}
                        data={{
                            title: `Asignaciones de ${rowSelected.row.fullName ?? rowSelected.row.document}`,
                            procede: "ASIGNAR"
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <ShoppingCartIcon />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar beneficiario"}
                        handleClick={handleClick}
                        data={{
                            title: "Modificar beneficiario",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!editable}
                    >
                        <EditOutlined />
                    </HeaderButton>

                    <HeaderButton
                        text={"Ver resumen"}
                        handleClick={handleClick}
                        data={{
                            title: `Resumen de ${rowSelected.row.fullName ?? rowSelected.row.document}`,
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <Timeline />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={styles.container}>
                <XGridServer
                    titles={titles}
                    updateList={getBeneficiarios}
                    forceUpload={forceUpload}
                    forceReset={resetTable}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}