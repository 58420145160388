import axiosPost from "./axiosPost";

export const RNConnectionDel = async (data, credentials) => {
    const {
        merchantServicioUsuarioId
    } = data;
    const body = {
        service: "RNConnectionDel",
        params: {
            merchantServicioUsuarioId
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
