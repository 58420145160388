import React, { useEffect, useState } from 'react';
import styles from "styles/pages/Pages.module.scss"
import XGrid from 'components/UI/Grid/XGrid';
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import { resumenNavidad as titlesAsignaciones } from 'consts/titulos-de-tablas';
import { v4 as uuidV4 } from "uuid";
import { RNPSResumenGet } from 'services/services-mia/RNPSResumenGet';
import Filters from 'components/Filters';
import { useFormik } from 'formik';
import moment from 'moment';
import { HeaderButton } from 'components';
import { transformNumber } from 'services/Local-services';
import * as yup from "yup";

export default function BasicTabs({ user }) {
    const credentials = useRecoilValue(userCredentials);
    const [asignaciones, setAsignaciones] = useState([]);
    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        fechaHasta: yup.date().required("Fecha hasta es requerido").max(new Date(), 'La fecha hasta no puede ser posterior a hoy'),
    });

    const formik = useFormik({
        initialValues: {
            fechaDesde: "2024-12-01",
            fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            getResumen(values)
        },
    });

    // console.log(user)
    const getResumen = async (values) => {
        setLoading(true);
        console.log(values)
        const response = await RNPSResumenGet(credentials, { tipoDoc: "DNI", entidadTipo: "U", usuarioDoc: user.document, usuarioSexo: user.sexo, fechaDesde: values.fechaDesde, fechaHasta: values.fechaHasta, tipoCuentaCod: "PECRA" });
        console.log(response)
        const mapAsignaciones = response.map((e) => {
            return {
                id: uuidV4(),
                "Fecha": moment(e?.movimientoFecha).format('DD/MM/YYYY'),
                "Movimiento": e?.tipoMovimientoNombre,
                "Ingreso": e?.movimientoDebito ? transformNumber(e?.movimientoDebito) : "",
                "Egreso": e?.movimientoCredito ? transformNumber(e?.movimientoCredito) : "",
                "Saldo": transformNumber(e?.saldoParcial),
                "Contraparte": e?.movimientoContraparte,
                "Referencia": e?.movimientoReferencia,
                "Programa": e?.programa,
            }
        });
        setAsignaciones(mapAsignaciones);
        setLoading(false);
    }
    console.log(asignaciones)
    useEffect(() => {
        if (formik.values) {
            getResumen(formik.values);
        }
    }, [])
    return (
        <main className={styles.main}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Filters
                    formik={formik}
                    inputsRender={
                        [
                            { type: "date", name: "fechaDesde", label: "Fecha desde", disabled: false },
                            { type: "date", name: "fechaHasta", label: "Fecha hasta", disabled: false },
                        ]
                    }
                    // disabled={isLoading}
                    circle={true}
                />
                {/* <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={loading} ><FilterAltOffRoundedIcon /></HeaderButton> */}
            </div>
            <XGrid rows={asignaciones} titles={titlesAsignaciones} loading={loading} />
        </main>
    );
}