import React, { useEffect, useRef, useState } from "react";

import { FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionUpd } from "services/services-mia/RNConnectionUpd";
import { RNESRubroUpd } from "services/services-mia/RNESRubroUpd";
import { RNESRubrosGet } from "services/services-mia/RNESRubrosGet";

const estados = [
    { estadoCod: "P", estadoNombre: "Pendiente" },
    { estadoCod: "A", estadoNombre: "Activo" },
    { estadoCod: "B", estadoNombre: "Baja" },
    { estadoCod: "S", estadoNombre: "Suspendido" },
    { estadoCod: "X", estadoNombre: "Cancelado" },
    { estadoCod: "R", estadoNombre: "Rechazado" },
];

const estadosTransiciones = {
    P: ["P", "X"],
    A: ["A", "B", "S"],
    B: ["B"],
    S: ["S", "A"],
    X: ["X", "P"],
    R: ["R", "B"],
};

const emailRegex =
    /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);
    const getRubrosData = async () => {
        setIsLoading(true);
        const result = await RNESRubrosGet(credentials, { rubroId: user.id })
        formikRef.current.setValues({
            id: result[0].RNESRubroId,
            nombre: result[0].RNESRubroNombre,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await RNESRubroUpd(credentials, {
            id: data.RNESRubroId,
            name: values.nombre,
            version: data.RNESRubroVersion,
        })
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Rubro modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getRubrosData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <FormikTextInput
                        fullWidth={true}
                        name="nombre"
                        placeholder=""
                        labelText="Nombre del rubro"
                    />
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Modificar" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
