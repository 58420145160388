import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularIndeterminate, FormButton } from "components";
import { FormikDateInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";
import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";
import { RNESRubrosGet } from "services/services-mia/RNESRubrosGet";
import { RNESRubroDel } from "services/services-mia/RNESRubroDel";
import { RNPSProgramaDel } from "services/services-mia/RNPSProgramaDel";
import { RNPSProgramaGet } from "services/services-mia/RNPSProgramaGet";

export default function DeleteModal({ user, updateList, closeModal, setterRowSelected }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const formikRef = useRef();
    const dataFormik = {
        inicio: { label: "Fecha de inicio" },
        fin: { label: "Fecha de fin" },
    }
    const getRubrosData = async () => {
        setIsLoading(true);
        const result = await RNPSProgramaGet(credentials, { programaId: user.id })
        // console.log(result);
        formikRef.current.setValues({
            id: result[0].rnpsProgramaId,
            nombre: result[0].rnpsProgramaNombre,
            cod: result[0].rnpsProgramaCodigo,
            inicio: result[0].rnpsProgramaFechaInicio,
            fin: result[0].rnpsProgramaFechaFin,
            version: result[0].rnpsProgramaVersion,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleDeletePrograma = async (values) => {
        const response = await RNPSProgramaDel(
            credentials,
            values
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Programa eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            setterRowSelected({ status: false, row: {} })
            closeModal();
        }
    }

    useEffect(() => {
        getRubrosData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                cod: "",
                inicio: "",
                fin: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe ingresar un codigo").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un codigo"),
                    inicio: yup.string("Debe ingresar una fecha de inicio").required("Debe ingresar una fecha de inicio"),
                    fin: yup.string("Debe ingresar una fecha de fin").required("Debe ingresar una fecha de fin"),
                })
            }
            onSubmit={(values) => {
                handleDeletePrograma(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            placeholder=""
                            labelText="Nombre del programa"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            placeholder=""
                            labelText="Código"
                            disabled={true}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="inicio"
                            labelText="Fecha de inicio"
                            disabled={true}
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("inicio", e.target.value);
                            }}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fin"
                            labelText="Fecha de fin"
                            disabled={true}
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fin", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
