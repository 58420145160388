import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { RNBeneficiarioGet } from "services/services-mia/RNBeneficiarioGet";
import { RNConnectionDel } from "services/services-mia/RNConnectionDel";

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];



export default function DeleteModal({ user, updateList, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [data, setData] = useState(null);
    const [inRequest, setInRequest] = useState(false);

    const [placeholderDNI, setPlaceholderDNI] = useState('')
    const [regexDNI, setRegexDNI] = useState(/./)

    const formikRef = useRef();

    const functions = {
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };
    console.log(user)
    const getData = async () => {
        const response = await RNBeneficiarioGet(
            credentials,
            {
                usuarioNroDeDocumentoX: user.documento,
                servicioHooliCod: "RNPSOCIAL"
            }
        );
        setData(response?.result[0]);
        formikRef.current.setFieldValue("dni", response?.result[0]?.usuarioNroDeDocumentoX);
        formikRef.current.setFieldValue("genero", response?.result[0]?.usuarioSexo === "M" ? "Masculino" : response?.result[0]?.usuarioSexo === "F" ? "Femenino" : "No Binario");
    };
    const handleDelete = async (values) => {
        const response = await RNConnectionDel(
            {
                merchantServicioUsuarioId: data.merchantServicioUsuarioId
            },
            credentials
        );
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Usuario eliminado correctamente",
                severity: "success",
                open: true,
            });
        }
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                dni: "",
                genero: "",
                rol: "BEN",
            }}
            validationSchema={
                yup.object({
                    dni: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .matches(regexDNI, { message: "Número de documento inválido" }),
                })
            }
            onSubmit={(values) => handleDelete(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="dni"
                            placeholder={placeholderDNI}
                            labelText="Número de DNI"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="genero"
                            labelText="Género"
                            disabled={true}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
