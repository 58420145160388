import axiosPost from "./axiosPost";

export const RNPSAsignacionIns = async (credentials, data) => {
    const body = {
        service: "RNPSAsignacionIns",
        params: {
            msuId: data?.usuarioMerchantId,
            rnpsAsignacionFecha: data?.fecha,
            rnpsAsignacionMonto: data?.cant,
            rnpsAsignacionRubroCod: data?.rubroId,
            rnpsProgramaId : Number(data?.programa),
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
