export const ACTIONS = {
    DELETE: "delete",
    INSERT: "insert",
    UPDATE: "update",
    ERROR: "error",
    GET: "get",
    EXPORT: "export",
    DETAIL: "detail",
    VIEW: "view"
};
