import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, DeleteModal } from "./Actions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { HeaderButton } from "components";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Buttons } from "components";
import { ACTIONS } from "consts/Actions";
import moment from "moment";
import { articulosTitles as titles } from "consts/titulos-de-tablas";
import styles from "styles/pages/Pages.module.scss";
import { useService } from "components/route/ServiceRoute";
import { connectionRolGet, rolGet } from "services/services-mia";
import Filters from "components/Filters";
import { RNESArticulosGet } from "services/services-mia/RNESArticulosGet";
import XGrid from "components/UI/Grid/XGrid";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { RNESRubrosGet } from "services/services-mia/RNESRubrosGet";

const rubros = [];
const genderName = {
    "M": "Masculino",
    "F": "Femenino",
    "X": "No binario"
}

export default function ArticulosEntregasSociales() {
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    const [rubros, setRubros] = useState([]);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [loading, setLoading] = useState(false);
    const [articulos, setArticulos] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [resetTable, setResetTable] = useState(false);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const { service } = useService();

    const formik = useFormik({
        initialValues: {
            rubroId: "",
        },
        onSubmit: (values) => {
            getArticulos(values)
        },
    })
    const resetForm = () => {
        formik.setFieldValue("rubroId", "");
        getArticulos({})
    };

    const getArticulos = async (data = { rubroId: null }) => {
        setArticulos([]);
        setLoading(true);
        const filter = await RNESRubrosGet(credentials);
        setRubros(filter);
        const result = await RNESArticulosGet(credentials, data);
        const newRows = result[0]?.RNESArticuloId ? result?.map((data) => {
            return {
                id: data.RNESArticuloId,
                name: data.RNESArticuloNombre,
                rubro: data.RNESRubroNombre,
                rubroId: data.RNESRubroId,
                plural: data.RNESArticuloNombrePlural,
                cant: data.RNESArticuloMaximo,
                version: data.RNESArticuloVersion,
            };
        }) : []
        setArticulos(newRows);
        setLoading(false);
    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    useEffect(() => {
        getArticulos()
    }, [])
    useEffect(() => {
        setRoute({
            wordKey: `Artículos - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])
    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={getArticulos}
                        closeModal={closeModal}
                        filtro={rubros}
                    />
                )}
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <DeleteModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={getArticulos}
                        closeModal={closeModal}
                        setterRowSelected={setRowSelected}
                        filtro={rubros}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        updateList={getArticulos}
                        closeModal={closeModal}
                        filtro={rubros}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "rubroId", label: "Rubro", disabled: false, options: rubros, firtsOptionKey: "RNESRubroId", firstOptionName: "RNESRubroNombre", firstOptionValue: "RNESRubroId" },
                            ]
                        }
                        disabled={loading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={loading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nuevo artículo"}
                        handleClick={handleClick}
                        data={{
                            title: "Nuevo artículo",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || service.servicioPermiso !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar artículo"}
                        handleClick={handleClick}
                        data={{
                            title: "Modificar artículo",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <EditOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar artículo"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar artículo",
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={styles.container}>
                <XGrid
                    rows={articulos}
                    titles={titles}
                    loading={loading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}