import React, { useEffect, useRef, useState } from 'react'
import { CircularIndeterminate, FormButton } from 'components'
import { FormikDateInput, FormikSelectInput, FormikTextInput } from 'components/UI/Inputs/FormikInputs';
import { Form, Formik } from "formik";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RNESArticulosGet } from 'services/services-mia/RNESArticulosGet';
import { RNESRubrosGet } from 'services/services-mia/RNESRubrosGet';
import styles from "styles/pages/ModalActions.module.scss";
import { RNESAsignacionesGet } from 'services/services-mia/RNESAsignacionesGet';
import { RNESAsignacionDel } from 'services/services-mia/RNESAsignacionesDel';

export default function DeleteModal({ updateList, roles, user, row, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [inRequest, setInRequest] = useState(false);
    const credentials = useRecoilValue(userCredentials);
    const [rubros, setRubros] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const formikRef = useRef();
    const getData = async () => {
        setIsLoading(true);
        // const rubrosFilter = await RNESRubrosGet(credentials);
        // setRubros(rubrosFilter)
        // const articulosData = await RNESArticulosGet(credentials);
        // setArticulos(articulosData)
        const result = await RNESAsignacionesGet(credentials, { rnesAsignacionMSUId: user.id, asignacionId: row.id })
        formikRef.current.setValues({
            articuloId: result[0].rnesArticuloNombre,
            cant: result[0].rnesAsignacionCantidad,
            fecha: result[0].rnesAsignacionFechaDisponible,
            rubroId: result[0].rnesRubroNombre,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleDeleteArticulo = async (values) => {
        const response = await RNESAsignacionDel(
            credentials,
            { asignacionId: data.rnesAsignacionId, version: data.rnesAsignacionVersion },
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Asignación eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    const dataFormik = {
        date: { label: "Fecha" },
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                rubroId: "",
                articuloId: "",
                cant: "",
                fecha: "",
            }}
            onSubmit={(values) => {
                handleDeleteArticulo(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="rubroId"
                            labelText="Rubro"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="articuloId"
                            labelText="Articulo"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cant"
                            labelText="Cantidad"
                            disabled={true}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fecha"
                            labelText="Fecha disponible"
                            disabled={true}
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fecha", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    )
}