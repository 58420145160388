import axiosPost from "./axiosPost";

export const RNESInformeGet = async (credentials, data) => {
    const {
        despachanteId,
        beneficiarioMSUId,
        rubroId,
        articuloId,
        fechaDesde,
        fechaHasta,
    } = data;
    const body = {
        service: "RNESInformeGet",
        params: {
            rnesMovimientoMSUId: beneficiarioMSUId,
            rnesMovimientoContraparteId: despachanteId,
            rnesRubroId: rubroId,
            rnesArticuloId: articuloId,
            fechaDesde,
            fechaHasta,
        },
    };

    const result = await axiosPost(body, credentials);

    return result.result;
};
