import axiosPost from "./axiosPost";

export const RNPSMerchantGet = async (credentials, data) => {
    const {
        merchantId,
        merchantCUIT,
        merchantPFPJ,
        merchantStatusOperativo,
        merchantStatusRegistro,
    } = data;
    const body = {
        service: "RNPSMerchantGet",
        params: {
            rnpsMerchantId: merchantId,
            merchantCUIT,
            merchantPFPJ,
            merchantStatusOperativo,
            merchantStatusRegistro,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
