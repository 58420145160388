import axiosPost from "./axiosPost";

export const RNESAsignacionUpd = async (credentials, data) => {
    const body = {
        service: "RNESAsignacionUpd",
        params: {
            rnesArticuloId: data?.articuloId,
            rnesAsignacionId: data?.asignacionId,
            rnesAsignacionVersion: data?.version,
            rnesAsignacionCantidad: data?.cant,
            rnesAsignacionFechaDisponible: data?.fecha,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
