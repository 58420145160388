import React, { useEffect, useRef, useState } from 'react'
import { CircularIndeterminate, FormButton } from 'components'
import { FormikDateInput, FormikSelectInput, FormikTextInput } from 'components/UI/Inputs/FormikInputs';
import { Form, Formik } from "formik";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RNESArticulosGet } from 'services/services-mia/RNESArticulosGet';
import { RNESRubrosGet } from 'services/services-mia/RNESRubrosGet';
import styles from "styles/pages/ModalActions.module.scss";
import { RNPSAsignacionesGet } from 'services/services-mia/RNPSAsignacionesGet';
import { RNPSAsignacionDel } from 'services/services-mia/RNPSAsignacionesDel';

export default function DeleteModal({ updateList, roles, user, row, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [inRequest, setInRequest] = useState(false);
    const credentials = useRecoilValue(userCredentials);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const formikRef = useRef();
    const getData = async () => {
        setIsLoading(true);
        const result = await RNPSAsignacionesGet(credentials, { rnesAsignacionMSUId: user.id, rnpsAsignacionId: row.id })
        console.log(result)
        formikRef.current.setValues({
            cant: result[0].rnpsAsignacionMonto,
            fecha: result[0].rnpsAsignacionFecha,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleDeleteArticulo = async (values) => {
        const response = await RNPSAsignacionDel(
            credentials,
            { asignacionId: data.rnpsAsignacionId },
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Asignación eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    const dataFormik = {
        date: { label: "Fecha" },
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                rubroId: "Alimentos",
                cant: "",
                fecha: "",
            }}
            onSubmit={(values) => {
                handleDeleteArticulo(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikTextInput
                            fullWidth={true}
                            name="rubroId"
                            labelText="Rubro"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cant"
                            labelText="Monto"
                            disabled={true}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fecha"
                            labelText="Fecha disponible"
                            disabled={true}
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fecha", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    )
}