import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import Modal from "components/UI/Modal/Modal";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { HeaderButton } from "components";
import { Buttons } from "components";
import { ACTIONS } from "consts/Actions";
import { programasNavidad as titles } from "consts/titulos-de-tablas";
import styles from "styles/pages/Pages.module.scss";
import { useService } from "components/route/ServiceRoute";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import XGrid from "components/UI/Grid/XGrid";
import * as yup from "yup";
import { UpdateModal } from "./Actions";
import { InsertModal } from "./Actions";
import { RNPSProgramaGet } from "services/services-mia/RNPSProgramaGet";
import moment from "moment";
import { DeleteModal } from "./Actions";

export default function ProgramasNavidad() {
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [loading, setLoading] = useState(false);
    const [programas, setProgramas] = useState([]);
    const { service } = useService();
    const validationSchema = yup.object({
        merchantCUIT: yup.string().matches(/^\d{2}-\d{8}-\d$/, "Debes ingresar un CUIT valido").nullable(),
    });
    const formik = useFormik({
        initialValues: {
            merchantCUIT: null,
            merchantPFPJ: null,
            merchantStatusOperativo: null,
            merchantStatusRegistro: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            // getProgramas(values)
        },
    })
    const resetForm = () => {
        formik.setFieldValue("merchantCUIT", "");
        formik.setFieldValue("merchantPFPJ", "");
        formik.setFieldValue("merchantStatusOperativo", "");
        formik.setFieldValue("merchantStatusRegistro", "");
        getProgramas({});
    };

    const getProgramas = async (values) => {
        setLoading(true);
        const response = await RNPSProgramaGet(credentials);
        console.log(response);
        const newRows = response ? response?.map((usuario) => {
            return {
                id: usuario.rnpsProgramaId,
                nombre: usuario.rnpsProgramaNombre,
                cod: usuario.rnpsProgramaCodigo,
                inicio: usuario.rnpsProgramaFechaInicio ? moment(usuario.rnpsProgramaFechaInicio).format("DD/MM/YYYY").toString() : null,
                fin: usuario.rnpsProgramaFechaFin ? moment(usuario.rnpsProgramaFechaFin).format("DD/MM/YYYY").toString() : null,
                version: usuario.rnpsProgramaVersion,
            };
        }) : [];
        setProgramas(newRows);
        setLoading(false);
    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
        getProgramas(formik.values);
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };



    useEffect(() => {
        setRoute({
            wordKey: `Programas - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])

    useEffect(() => {
        getProgramas({});
    }, [])

    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        // roles={connectionRoles}
                        updateList={getProgramas}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <DeleteModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={getProgramas}
                        closeModal={closeModal}
                        setterRowSelected={setRowSelected}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        updateList={getProgramas}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions-right"]}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { getProgramas() }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nuevo programa"}
                        handleClick={handleClick}
                        data={{
                            title: "Nuevo programa",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || service.servicioPermiso !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar programa"}
                        handleClick={handleClick}
                        data={{
                            title: "Modificar programa",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <EditOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar programa"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar programa",
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={styles.container}>
                <XGrid
                    rows={programas}
                    titles={titles}
                    loading={loading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}