import React, { useEffect, useRef, useState } from 'react'
import { CircularIndeterminate, FormButton } from 'components'
import { FormikDateInput, FormikSelectInput, FormikTextInput } from 'components/UI/Inputs/FormikInputs';
import { Form, Formik } from "formik";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RNESArticulosGet } from 'services/services-mia/RNESArticulosGet';
import { RNESRubrosGet } from 'services/services-mia/RNESRubrosGet';
import styles from "styles/pages/ModalActions.module.scss";
import * as yup from "yup";
import { RNESAsignacionIns } from 'services/services-mia/RNESAsignacionIns';

export const InsertModal = ({ updateList, roles, user, closeModal, coords }) => {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [inRequest, setInRequest] = useState(false);
    const credentials = useRecoilValue(userCredentials);
    const [rubros, setRubros] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [articulosFilter, setArticulosFilter] = useState([]);
    const [articleSelected, setArticleSelected] = useState();
    const formikRef = useRef();

    const getData = async () => {
        const rubrosFilter = await RNESRubrosGet(credentials);
        setRubros(rubrosFilter)
        const articulosData = await RNESArticulosGet(credentials);
        setArticulos(articulosData)
        setArticulosFilter(articulosData)
    }
    const handleCreateAsignacion = async (values) => {
        const response = await RNESAsignacionIns(
            credentials,
            { usuarioMerchantId: user.id, articuloId: values.articuloId, cant: values.cant, longitud: coords.longitud, latitud: coords.latitud, fecha: values.fecha },
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Asignación creada correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }

    const dataFormik = {
        date: { label: "Fecha" },
    }

    useEffect(() => {
        getData()
    }, []);
    console.log(articulosFilter, articleSelected)
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                userId: "",
                articuloId: "",
                cant: "",
                fecha: "",
            }}
            validationSchema={
                yup.object({
                    articuloId: yup.string("Debe ingresar un articulo").required("Debe ingresar un articulo"),
                    cant: yup.number("Debe ingresar una cantidad").max(articleSelected?.RNESArticuloMaximo, `Debe ser menor a ${articleSelected?.RNESArticuloMaximo}`).min(1, "Debe ser mayor a 0").required("Debe ingresar una cantidad"),
                    fecha: yup.date("Debe seleccionar una fecha").required("Debe seleccionar una fecha"),
                })
            }
            onSubmit={(values) => {
                handleCreateAsignacion(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="rubroId"
                            labelText="Rubro"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("rubroId", e.target.value);
                                let filter = articulos.filter((item) => item.RNESRubroId === Number(e.target.value))
                                setArticulosFilter(filter)
                            }}
                        >
                            <option value="" selected disabled >Seleccione</option>
                            {rubros.map(({ RNESRubroId, RNESRubroNombre }) => (
                                <option
                                    key={RNESRubroId}
                                    data-id={RNESRubroId}
                                    value={RNESRubroId}
                                >
                                    {RNESRubroNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="articuloId"
                            labelText="Articulo"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("articuloId", e.target.value);
                                let filter = articulosFilter.find((item) => item.RNESArticuloId === Number(e.target.value))
                                setArticleSelected(filter)
                            }}
                        >
                            <option value="" selected disabled >Seleccione</option>
                            {articulosFilter.map(({ RNESArticuloId, RNESArticuloNombre }) => (
                                <option
                                    key={RNESArticuloId}
                                    data-id={RNESArticuloId}
                                    value={RNESArticuloId}
                                >
                                    {RNESArticuloNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="cant"
                            labelText="Cantidad"
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fecha"
                            labelText="Fecha disponible"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fecha", e.target.value);
                            }}
                        />
                    </>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Agregar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    )
}
