import axiosPost from "./axiosPost";

export const RNPSAsignacionesGet = async (credentials, data) => {
    const body = {
        service: "RNPSAsignacionGet",
        params: {
            msuId: data?.msuId,
            rnpsAsignacionId: data?.rnpsAsignacionId,
            rnpsProgramaNombre: data?.rnpsProgramaNombre,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
