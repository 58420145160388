import React, { useEffect, useRef, useState } from "react";

import { Buttons, CircularIndeterminate, FormButton, HeaderButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/Pages.module.scss"
import { handleResponse } from "services/Local-services";
import { RNCAAsignacionDo } from "services/services-mia/RNCAAsignacionDo";
import XGrid from "components/UI/Grid/XGrid";
import { asignacionTitles as titles } from 'consts/titulos-de-tablas';
import { Add, Cached, EditOutlined } from "@mui/icons-material";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ACTIONS } from "consts/Actions";
import Modal from "components/UI/Modal/Modal";
import { InsertModal } from "./AsignacionModales/InsertModal";
import { UpdateModal } from "./AsignacionModales/UpdateModal";
import DeleteModal from "./AsignacionModales/DeleteModal";
import { RNESAsignacionesGet } from "services/services-mia/RNESAsignacionesGet";
import moment from "moment";

export default function AsignarGarrafas({ updateList, servicio, closeModal, user }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [coords, setCoords] = useState({ latitud: null, longitud: null })
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [inRequest, setInRequest] = useState(false);
    const [rowSelectedSecond, setRowSelectedSecond] = useState({ status: false, row: {} });
    const [asignaciones, setAsignaciones] = useState([]);
    const [loading, setLoading] = useState(false);
    const getAsigncaiones = async () => {
        setAsignaciones([]);
        setLoading(true);
        const result = await RNESAsignacionesGet(credentials, { rnesAsignacionMSUId: user.id });
        const newRows = result[0]?.rnesAsignacionId ? result?.map((data) => {
            return {
                id: data?.rnesAsignacionId,
                name: data?.rnesArticuloNombre,
                rubro: data?.rnesRubroNombre,
                plural: data?.rnesArticuloNombrePlural,
                cant: data?.rnesAsignacionCantidad,
                version: data?.rnesAsignacionVersion,
                fechaEntrega: data?.rnesAsignacionFechaDisponible ? moment(data?.rnesAsignacionFechaDisponible).format("DD/MM/YYYY").toString() : null,
                cantDisponible: data?.rnesAsignacionCantidad - data?.rnesAsignacionCantidadDisponible,
                fechaAsignacion: data?.rnesAsignacionFHAlta ? moment(data?.rnesAsignacionFHAlta).format("DD/MM/YYYY HH:mm:ss").toString() : null,
                merchantServicioUsuarioId: user?.merchantServicioUsuarioId,
                asignado: `${data.rnesMovimientoContraparteNombre} ${data.rnesMovimientoContraparteDNI ? "(" + data.rnesMovimientoContraparteDNI + ")" : ""}`
            };
        }) : []
        setAsignaciones(newRows);
        setLoading(false);
    }
    const updateSecondList = () => {
        getAsigncaiones();
        setRowSelectedSecond({ status: false, row: {} });
    };
    const closeSecondModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoords({ latitud: data.coords.latitude.toFixed(6), longitud: data.coords.longitude.toFixed(6) })
            }
        );
        getAsigncaiones();
    }, [])
    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal} close={modalDetails.procede === ACTIONS.EXPORT || modalDetails.procede === "ASIGNAR" ? true : false} >
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        user={user}
                        // roles={connectionRoles}
                        updateList={updateSecondList}
                        closeModal={closeSecondModal}
                        coords={coords}
                    />
                )}
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={user}
                        row={rowSelectedSecond.row}
                        // roles={connectionRoles}
                        updateList={updateSecondList}
                        closeModal={closeSecondModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={user}
                        row={rowSelectedSecond.row}
                        // roles={connectionRoles}
                        updateList={updateSecondList}
                        closeModal={closeSecondModal}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions-right"]}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { getAsigncaiones() }}
                        disabled={rowSelectedSecond.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear asignación"}
                        handleClick={handleClick}
                        data={{
                            title: `Crear asignación para ${user.fullName || user.document}`,
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelectedSecond.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar asignación"}
                        handleClick={handleClick}
                        data={{
                            title: `Modificar asignación para ${user.fullName || user.document}`,
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelectedSecond.row.id || rowSelectedSecond.row.cantDisponible !== 0}
                    >
                        <EditOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar asignación para"}
                        handleClick={handleClick}
                        data={{
                            title: `Eliminar asignación ${user.fullName || user.document}`,
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelectedSecond.row.id || rowSelectedSecond.row.cantDisponible !== 0}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                </Buttons>
            </div>
            <XGrid rows={asignaciones} titles={titles} loading={loading} secondarySelected={rowSelectedSecond} setSecondarySelected={setRowSelectedSecond} />
        </main>
    );
}
