import CircularIndeterminate from "components/utils/spinner/Spinner";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { rioNegroServices } from "recoilState/GlobalState";

const ServiceRoute = ({
    redirectProtectedRoute,
    servicioCod
}) => {
    const [loading, setLoading] = useState(true)
    const [service, setService] = useState()
    const services = useRecoilValue(rioNegroServices);

    const location = useLocation();

    useEffect(() => {
        if (services.length === 0) return;
        setLoading(true);
        if (!servicioCod) {
            setService(null)
            setLoading(false);
            return;
        };
        const currentService = services.find(service => service.servicioCod === servicioCod);

        if (!currentService || currentService?.servicioContratado === 'N' || currentService?.servicioAutorizado === 'N' || currentService?.servicioPermiso === 'N') {
            setService(null)
            setLoading(false);
            return;
        }
        setService(currentService);
        setLoading(false);
    }, [servicioCod, services]);


    if (loading) return <CircularIndeterminate />
    if (service) return <Outlet context={{ service }} />;
    return <Navigate to={redirectProtectedRoute} state={{ from: location }} replace />;
}

export default ServiceRoute;

export const useService = () => {
    return useOutletContext()
}
