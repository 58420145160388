import axiosPost from "./axiosPost";

export const RNPSResumenGet = async (credentials, data) => {
    const body = {
        service: "RNPSResumenGet",
        params: {
            entidadTipoCod: data?.entidadTipo,
            tipoCuentaCod: data?.tipoCuentaCod,
            tipoDeDocumentoCod: data?.tipoDoc,
            usuarioNroDeDocumento: data?.usuarioDoc,
            usuarioSexo: data?.usuarioSexo,
            merchantCUIT: data?.merchantCUIT || null,
            fechaDesde: data?.fechaDesde || "2023-01-01",
            fechaHasta: data?.fechaHasta || "2024-11-30",
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
