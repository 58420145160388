import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import moment from "moment";

// utils
import { InsertModal, UpdateModal, ExportModal } from "./Actions";
import XGridServer from "components/UI/Grid/XGridServer";
import { Buttons, HeaderButton } from "components";
// consts
import { usuariosXServicio as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
//Assets
import { Add, Cached, EditOutlined, CloudDownload, VisibilityOutlined } from '@mui/icons-material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { rioNegroServices, routeInfo, userCredentials } from "recoilState/GlobalState";
import { clienteUsuarioGet, connectionRolGet, rolGet } from "services/services-mia";

import Filters from "components/Filters";

import { useService } from "components/route/ServiceRoute";
import { Link } from "react-router-dom";

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";

/*const serviceStatus = [
    {
        servicioStatusCod: '',
        servicioStatusNombre: 'Todos'
    },
    {
        servicioStatusCod: 'A',
        servicioStatusNombre: 'Activos'
    },
    {
        servicioStatusCod: 'P',
        servicioStatusNombre: 'Pendientes'
    },
    {
        servicioStatusCod: 'B',
        servicioStatusNombre: 'Bajas'
    },
    {
        servicioStatusCod: 'R',
        servicioStatusNombre: 'Rechazados'
    },
    {
        servicioStatusCod: 'S',
        servicioStatusNombre: 'Suspendidos'
    },
    {
        servicioStatusCod: 'X',
        servicioStatusNombre: 'Cancelados'
    }
];*/

const onboardStatus = [
    {
        onboardStatusCod: '',
        onboardStatusNombre: 'Todos'
    },
    {
        onboardStatusCod: 'Y',
        onboardStatusNombre: 'Sí'
    },
    {
        onboardStatusCod: 'N',
        onboardStatusNombre: 'No'
    }
];

export default function UsuariosXServicio() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [roles, setRoles] = useState([]);
    const [editable, setEditable] = useState(false);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const { service } = useService()
    const services = useRecoilValue(rioNegroServices);

    const setRoute = useSetRecoilState(routeInfo);
    const credentials = useRecoilValue(userCredentials);

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const formik = useFormik({
        initialValues: {
            apellido: "",
            dni: "",
            rol: "Todos",
            estado: "",
            onboard: "",
        },
        onSubmit: () => {
            updateList()
        },
    });

    const getUsers = async (page, pageSize) => {
        const { result, total } = await clienteUsuarioGet({
            merchantId: credentials?.merchantId,
            tipoRelacion: "S",
            estado: formik.values.estado || null,
            usuarioTieneApp: formik.values.onboard || null,
            servicioCod: service.servicioCod,
            page: page + 1,
            pageSize,
            usuarioNroDeDocumentoX: formik.values.dni === "" ? null : formik.values.dni,
            usuarioApellidos: formik.values.apellido === "" ? null : formik.values.apellido,
            rolCod: formik.values.rol === "Todos" ? null : formik.values.rol
        }, credentials);

        const newRows = await result.map((usuario) => {
            return {
                id: usuario.merchantServicioUsuarioId,
                usuarioId: usuario.usuarioId,
                nombreServicio: usuario.servicioNombre,
                servicioId: usuario.servicioId,
                estadoCod: usuario.relacionStatusCod,
                email: usuario.usuarioMail,
                rolId: usuario.rolId,
                rolCod: usuario.rolCod,
                rolNombre: usuario.rolNombre,
                [titles[0]]: usuario.usuarioNombreCompleto,
                // [titles[1]]: usuario.tipoDeDocumentoNombre,
                [titles[1]]: `${usuario.usuarioNroDeDocumento}`,
                [titles[2]]:
                    usuario.usuarioSexo === "M"
                        ? "Masculino"
                        : usuario.usuarioSexo === "F"
                            ? "Femenino"
                            : usuario.usuarioSexo === "X"
                                ? "No binario"
                                : "",
                [titles[3]]: usuario.usuarioFechaDeNacimiento,
                [titles[4]]: usuario.rolNombre,
                [titles[5]]: usuario.usuarioId === null ? 'NO' : 'SI',
                [titles[6]]: usuario.usuarioFechaDeAlta ? moment(usuario.usuarioFechaDeAlta).format("YYYY-MM-DD").toString() : null,
                [titles[7]]: usuario.usuarioFecha,
                [titles[8]]: usuario.relacionStatus
            };
        });
        return {
            rows: newRows,
            rowCount: total,
        };
    };

    useEffect(() => {
        setRoute({
            wordKey: `Usuarios - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])

    useEffect(() => {
        if (Object.keys(credentials).length > 0 && service?.servicioCod) {
            (async () => {
                const resultRoles = await rolGet(service.servicioCod, credentials);
                const connectionRoles = await connectionRolGet(service.servicioCod, credentials)
                setConnectionRoles(connectionRoles.result);
                setRoles([{ rolCod: 'Todos', rolNombre: 'Todos' }, ...resultRoles.result]);
            })();
        }
    }, [credentials, service])

    useEffect(() => {
        if (rowSelected.row.id && service.servicioPermiso === 'E' && connectionRoles.some(rol => rol.rolCod === rowSelected.row.rolCod)) {
            setEditable(true);
            return;
        }
        setEditable(false);
    }, [connectionRoles, rowSelected.row.id, rowSelected.row.rolCod, service])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <ExportModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <Filters
                    formik={formik}
                    inputsRender={
                        [
                            { type: "text", name: "apellido", label: "Apellido", disabled: false },
                            { type: "text", name: "dni", label: "Documento", disabled: false },
                            { type: "select", name: "rol", label: "Rol", disabled: false, options: roles, firtsOptionKey: "rolCod", firstOptionName: "rolNombre", firstOptionValue: "rolCod" },
                            { type: "select", name: "onboard", label: "Onboard", disabled: false, options: onboardStatus, firtsOptionKey: "onboardStatusCod", firstOptionName: "onboardStatusNombre", firstOptionValue: "onboardStatusCod" },
                            // { type: "select", name: "estado", label: "Estado", disabled: false, options: serviceStatus, firtsOptionKey: "servicioStatusCod", firstOptionName: "servicioStatusNombre", firstOptionValue: "servicioStatusCod" }
                        ]
                    }
                />
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <Link to={`/usuarios/${rowSelected.row.usuarioId}/details`}>
                        <HeaderButton
                            text={"Ver detalle"}
                            handleClick={() => { }}
                            disabled={!rowSelected.row.id}
                        >
                            <VisibilityOutlined />
                        </HeaderButton>
                    </Link>
                    <HeaderButton
                        text={"Crear relación"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear conexión",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || service.servicioPermiso !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar relación"}
                        handleClick={handleClick}
                        data={{
                            title: "Actualizar conexión",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!editable}
                    >
                        <EditOutlined />
                    </HeaderButton>

                    <HeaderButton
                        text={"Descargar Excel"}
                        handleClick={handleClick}
                        data={{
                            title: "Descargar archivo Excel",
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={rowSelected.row.id}
                    >
                        <CloudDownload />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getUsers}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
