import axiosPost from "./axiosPost";

export const RNPSProgramaUpd = async (credentials, data) => {
    const body = {
        service: "RNPSProgramaUpd",
        params: {
            rnpsProgramaId: data?.id,
            rnpsProgramaCodigo: data?.cod,
            rnpsProgramaNombre: data?.nombre,
            rnpsProgramaFechaFin: data?.fin,
            rnpsProgramaFechaInicio: data?.inicio,
            rnpsProgramaVersion: data?.version,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
