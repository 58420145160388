import axiosPost from "./axiosPost";

export const RNESArticuloDel = async (credentials, data) => {
    const body = {
        service: "RNESArticuloDel",
        params: {
            rnesArticuloId: data?.id,
        },
    };

    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
