import { max, min } from "moment";

export const usuariosXServicio = [
    {
        field: "fullName",
        headerName: "Apellido y Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "document",
        headerName: "Documento",
        type: "number",
        format: "number",
        width: 120,
    },
    {
        field: "gender",
        headerName: "Género",
        width: 70,
        align: "center",
    },
    {
        field: "birthDay",
        headerName: "Nacimiento",
        type: "date",
        format: "date",
    },
    {
        field: "rol",
        headerName: "Rol",
        minWidth: 240,
    },
    {
        field: "onboard",
        headerName: "Onboard",
        align: "center",
    },
    {
        field: "creationDate",
        headerName: "Alta",
        type: "date",
        format: "date",
    },
    {
        field: "status",
        headerName: "Estado",
    },
    {
        field: "statusDate",
        headerName: "Fecha",
        type: "date",
        format: "date",
    },
    {
        field: "asigaciones",
        headerName: "Asignaciones",
    },
];
export const usuariosXServicioNavidad = [
    {
        field: "fullName",
        headerName: "Apellido y Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "document",
        headerName: "Documento",
        type: "number",
        format: "number",
        width: 120,
    },
    {
        field: "gender",
        headerName: "Género",
        width: 70,
        align: "center",
    },
    {
        field: "birthDay",
        headerName: "Nacimiento",
        type: "date",
        format: "date",
    },
    {
        field: "rol",
        headerName: "Rol",
        minWidth: 240,
    },
    {
        field: "onboard",
        headerName: "Onboard",
        align: "center",
    },
    {
        field: "creationDate",
        headerName: "Alta",
        type: "date",
        format: "date",
    },
    {
        field: "wallet",
        headerName: "Billetera",
    },
    {
        field: "status",
        headerName: "Estado",
    },
    {
        field: "statusDate",
        headerName: "Fecha",
        type: "date",
        format: "date",
    },
    {
        field: "asignaciones",
        headerName: "Asignaciones",
    },
    // {
    //     field: "consumo",
    //     headerName: "Consumo",
    // },
];
export const comerciosNavidad = [
    {
        field: "cuit",
        headerName: "CUIT",
        flex: 1,
        headerAlign: "right",
        align: "right",
        maxWidth: 180,
    },
    {
        field: "RZ",
        headerName: "Razon Social",
        minWidth: 380,
    },
    {
        field: "merchantNombre",
        headerName: "Nombre",
        minWidth: 400,
    },
    {
        field: "localidad",
        headerName: "Localidad",
        minWidth: 280,
    },
    {
        field: "fisicaJuridica",
        headerName: "Física/Jurídica",
        maxWidth: 180,    
    },
    {
        field: "statusOperativo",
        headerName: "Estado Operativo",
        maxWidth: 200,
    },
    {
        field: "statusRegistro",
        headerName: "Estado de Registro",
        maxWidth: 150,
    },
    {
        field: "fechaAlta",
        headerName: "Fecha alta",
        type: "date",
        format: "date",
        maxWidth: 150,
    },
];
export const programasNavidad = [
    {
        field: "nombre",
        headerName: "Nombre",
        flex: 1,
    },
    {
        field: "cod",
        headerName: "Código",
        flex: 1,
    },
    {
        field: "inicio",
        headerName: "Inicio",
        flex: 1,
    },
    {
        field: "fin",
        headerName: "Fin",
        flex: 1,
    },
];
export const UsuariosGlobales = [
    {
        field: "fullName",
        headerName: "Apellido y Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "document",
        headerName: "Documento",
        type: "number",
        format: "number",
        width: 120,
    },
    {
        field: "gender",
        headerName: "Género",
        width: 70,
        align: "center",
    },
    {
        field: "birthDay",
        headerName: "Nacimiento",
        type: "date",
        format: "date",
    },
    {
        field: "nombreServicio",
        headerName: "Servicio",
        minWidth: 240,
    },
    {
        field: "rol",
        headerName: "Rol",
        minWidth: 240,
    },
    {
        field: "onboard",
        headerName: "Onboard",
        align: "center",
    },
    {
        field: "creationDate",
        headerName: "Alta",
        type: "date",
        format: "date",
    },
    {
        field: "status",
        headerName: "Estado",
    },
    {
        field: "statusDate",
        headerName: "Fecha",
        type: "date",
        format: "date",
    },
];
export const rubrosTitles = [
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 180,
    },
];

export const articulosTitles = [
    {
        field: "name",
        headerName: "Nombre del artículo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "rubro",
        headerName: "Rubro",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "plural",
        headerName: "Plural del artículo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cant",
        headerName: "Cantidad máxima",
        flex: 1,
        minWidth: 180,
    },
];

export const asignacionTitles = [
    {
        field: "name",
        headerName: "Articulo",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "rubro",
        headerName: "Rubro",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "fechaEntrega",
        headerName: "Fecha disponible",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cant",
        headerName: "Cantidad",
        headerAlign: "center",
        align: "right",
        minWidth: 50,
        maxWidth: 180,
    },
    {
        field: "cantDisponible",
        headerName: "Cantidad entregada",
        minWidth: 50,
        maxWidth: 180,
        align: "right",
    },
    {
        field: "asignado",
        headerName: "Asignado por",
        minWidth: 340,
    },
    {
        field: "fechaAsignacion",
        headerName: "Fecha de asignación",
        flex: 1,
        minWidth: 180,
    },
];

export const asignacionNavidadTitles = [
    {
        field: "fecha",
        headerName: "Fecha",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "rubro",
        headerName: "Rubro",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "programa",
        headerName: "Programa",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "cant",
        headerName: "Monto",
        flex: 1,
        minWidth: 180,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "asignado",
        headerName: "Asignado por",
        minWidth: 340,
    },
    {
        field: "fechaAsignacion",
        headerName: "Fecha de asignación",
        flex: 1,
        minWidth: 180,
    },
];


export const movimientosBeneficiario = [
    "Fecha",
    "Cantidad",
    "Artículo",
    "Entregado por",
    "Identificador",
    "Latitud y Longitud",
];

export const movimientosDespachante = [
    "Fecha",
    "Cantidad",
    "Artículo",
    "Entregado a",
    "Identificador",
    "Latitud y Longitud",
];

export const resumenDisponibles = ["Fecha disponible", "Articulo", "Cantidad", "Asignado por", "Fecha asignación"];
export const resumenTransferencias = [
    "Fecha disponible",
    "Articulo",
    "Cantidad",
    "Transferido a ",
    "Fecha asignación"
];
export const resumenAsignaciones = [
    "Fecha disponible",
    "Articulo",
    "Cantidad",
    "Asignado por",
    "Fecha asignación"
];
export const resumenRetiro = [
    "Fecha entrega",
    "Articulo",
    "Cantidad",
    "Entregado por ",
    "Fecha disponible",
];
export const resumenNavidad = [
    {
        field: "Fecha",
        headerName: "Fecha",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Movimiento",
        headerName: "Movimiento",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Ingreso",
        headerName: "Ingreso",
        flex: 1,
        minWidth: 180,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "Egreso",
        headerName: "Egreso",
        flex: 1,
        minWidth: 180,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "Saldo",
        headerName: "Saldo",
        minWidth: 200,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "Contraparte",
        headerName: "Contraparte",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Referencia",
        headerName: "Referencia",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Programa",
        headerName: "Programa",
        flex: 1,
        minWidth: 180,
    }
]
export const resumenComerciosNavidad = [
    {
        field: "Fecha",
        headerName: "Fecha",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Movimiento",
        headerName: "Movimiento",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Ingreso",
        headerName: "Ingreso",
        flex: 1,
        minWidth: 180,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "Egreso",
        headerName: "Egreso",
        flex: 1,
        minWidth: 180,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "Saldo",
        headerName: "Saldo",
        minWidth: 200,
        align: "right",
        headerAlign: "right",
    },
    {
        field: "Contraparte",
        headerName: "Contraparte",
        flex: 1,
        minWidth: 180,
    },
    {
        field: "Referencia",
        headerName: "Referencia",
        flex: 1,
        minWidth: 180,
    },
]