import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import moment from "moment";

// utils
import { InsertModal, UpdateModal, ExportModal } from "./Actions";
import XGridServer from "components/UI/Grid/XGridServer";
import { Buttons, HeaderButton } from "components";
// consts
import { usuariosXServicio as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
//Assets
import { Add, Cached, EditOutlined, CloudDownload, VisibilityOutlined } from '@mui/icons-material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { connectionRolGet, rolGet } from "services/services-mia";
import { RNUsuarioGet } from "services/services-mia/RNUsuarioGet.js"
import Filters from "components/Filters";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { useService } from "components/route/ServiceRoute";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DeleteModal from "./Actions/DeleteModal";

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import DetailModal from "./Actions/DetailModal";

/*const serviceStatus = [
    {
        servicioStatusCod: '',
        servicioStatusNombre: 'Todos'
    },
    {
        servicioStatusCod: 'A',
        servicioStatusNombre: 'Activos' 
    },
    {
        servicioStatusCod: 'P',
        servicioStatusNombre: 'Pendientes'
    },
    {
        servicioStatusCod: 'B',
        servicioStatusNombre: 'Bajas'
    },
    {
        servicioStatusCod: 'R',
        servicioStatusNombre: 'Rechazados'
    },
    {
        servicioStatusCod: 'S',
        servicioStatusNombre: 'Suspendidos'
    },
    {
        servicioStatusCod: 'X',
        servicioStatusNombre: 'Cancelados'
    }
];*/

const onboardStatus = [
    {
        onboardStatusCod: 'Y',
        onboardStatusNombre: 'Sí'
    },
    {
        onboardStatusCod: 'N',
        onboardStatusNombre: 'No'
    }
];

const genderName = {
    "M": "Masculino",
    "F": "Femenino",
    "X": "No binario"
}


export default function UsuariosXServicio() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [roles, setRoles] = useState([]);
    const [editable, setEditable] = useState(false);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [resetTable, setResetTable] = useState(false);
    const [forceUpload, setForceUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const { service } = useService()

    const setRoute = useSetRecoilState(routeInfo);
    const credentials = useRecoilValue(userCredentials);

    const updateList = useCallback(() => {
        setRowSelected({ status: false, row: {} })
        setForceUpload((value) => !value);
    }, [])

    const formik = useFormik({
        initialValues: {
            apellido: null,
            dni: null,
            rol: null,
            estado: null,
            onboard: null,
        },
        onSubmit: () => {
            setResetTable((value) => !value);
        },
    });

    const resetForm = () => {
        formik.setFieldValue("apellido", "");
        formik.setFieldValue("dni", "");
        formik.setFieldValue("rol", "");
        formik.setFieldValue("onboard", "");
        setResetTable(!resetTable)
        setForceUpload(!forceUpload);
        setRowSelected({ status: false, row: {} })
        updateList()
    };
    const getUsers = async (page, pageSize) => {
        setLoading(true);
        const data = {
            rolCod: formik.values.rol,
            servicioHooliCod: service.servicioCod,
            page: page + 1,
            pageSize,
            usuarioTieneApp: formik.values.onboard || null,
            usuarioNroDeDocumentoX: formik.values.dni || null,
            usuarioApellidos: formik.values.apellido || null,
            merchantServicioUsuarioStatus: null,
            fechaDesde: null,
            fechaHasta: null,
            fechaTipo: null,
        }
        const { result, total } = await RNUsuarioGet(credentials, data);
        const newRows = result ? result.map((usuario) => {
            return {
                id: usuario.merchantServicioUsuarioId,
                usuarioId: usuario.usuarioId,
                nombreServicio: usuario.servicioNombre,
                servicioId: usuario.servicioId,
                estadoCod: usuario.relacionStatusCod,
                email: usuario.usuarioMail,
                rolId: usuario.rolId,
                rolCod: usuario.rolCod,
                rolNombre: usuario.rolNombre,
                [titles[0].field]: usuario.usuarioNombreCompleto,
                [titles[1].field]: usuario.usuarioNroDeDocumentoX,
                [titles[2].field]: genderName[usuario.usuarioSexo],
                [titles[3].field]: usuario.usuarioFechaDeNacimiento,
                [titles[4].field]: usuario.rolNombre,
                [titles[5].field]: usuario.usuarioId === null ? 'NO' : 'SI',
                [titles[6].field]: usuario.usuarioFechaDeAlta ? moment(usuario.usuarioFechaDeAlta).format("YYYY-MM-DD").toString() : null,
                [titles[7].field]: usuario.relacionStatus,
                [titles[8].field]: usuario.usuarioFecha,
            };
        }) : [];
        setLoading(false);
        return {
            rows: newRows,
            rowCount: total
        }
    };

    useEffect(() => {
        setRoute({
            wordKey: `Usuarios - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])

    useEffect(() => {
        if (Object.keys(credentials).length > 0 && service?.servicioCod) {
            (async () => {
                const resultRoles = await rolGet(service.servicioCod, credentials);
                const connectionRoles = await connectionRolGet(service.servicioCod, credentials)
                const rolesConnectionFilter = connectionRoles.result.filter((e) => e.rolCod === "MA" || e.rolCod === "OPE" || e.rolCod === "VIS")
                const rolesFilter = resultRoles.result.filter((e) => e.rolCod === "MA" || e.rolCod === "OPE" || e.rolCod === "VIS")
                setConnectionRoles(rolesConnectionFilter);
                setRoles([...rolesFilter]);
            })();
        }
    }, [credentials, service])

    useEffect(() => {
        if (rowSelected.row.id && service.servicioPermiso === 'E' && connectionRoles.some(rol => rol.rolCod === rowSelected.row.rolCod)) {
            setEditable(true);
            return;
        }
        setEditable(false);
    }, [connectionRoles, rowSelected.row.id, rowSelected.row.rolCod, service])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}

                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <DetailModal
                        usuarioId={rowSelected.row.usuarioId}
                        service={service}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        service={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "text", name: "apellido", label: "Apellido", disabled: false },
                                { type: "text", name: "dni", label: "Documento", disabled: false },
                                { type: "select", name: "rol", label: "Rol", disabled: false, options: roles, firtsOptionKey: "rolCod", firstOptionName: "rolNombre", firstOptionValue: "rolCod" },
                                { type: "select", name: "onboard", label: "Onboard", disabled: false, options: onboardStatus, firtsOptionKey: "onboardStatusCod", firstOptionName: "onboardStatusNombre", firstOptionValue: "onboardStatusCod" },
                            ]
                        }
                        disabled={loading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={loading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver detalle"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalle del Usuario",
                            procede: ACTIONS.DETAIL
                        }}
                        disabled={!rowSelected.row.id || !rowSelected.row.usuarioId}
                    >
                        <VisibilityOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nuevo usuario"}
                        handleClick={handleClick}
                        data={{
                            title: "Nuevo usuario",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || service.servicioPermiso !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar usuario"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar usuario",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <DeleteOutlineRoundedIcon />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar usuario"}
                        handleClick={handleClick}
                        data={{
                            title: "Modificar usuario",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!editable}
                    >
                        <EditOutlined />
                    </HeaderButton>


                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getUsers}
                    forceReset={resetTable}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
