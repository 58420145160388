import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { connectionIns, notificatorSend, paisGet, tipoDeDocumentoGet, usuarioBasicosGet } from "services/services-mia";
import { RNConnectionIns } from "services/services-mia/RNConnectionIns";

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];

const emailRegex = /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function InsertModal({ updateList, roles, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [paises, setPaises] = useState([]);
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);

    const [placeholderDNI, setPlaceholderDNI] = useState('')
    const [regexDNI, setRegexDNI] = useState(/./)

    const formikRef = useRef();

    const functions = {
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getPais = useCallback(async () => {
        setIsLoading(true);
        const { result: paises, status } = await paisGet(credentials);
        if (status.code !== 1) {
            setIsLoading(false);
            return;
        }
        setPaises(paises);
        const paisId = await new Promise(((resolve) => {
            navigator.geolocation.getCurrentPosition((data) => {
                const userLat = data.coords.latitude;
                const userLng = data.coords.longitude;
                let paisIdSelected = paises[0].paisId;
                let Latitud = paises[0].paisLatitudCentro - userLat;
                let Longitud = paises[0].paisLongitudCentro - userLng;
                for (let i = 1; i < paises.length; i++) {
                    const { paisLatitudCentro: lat, paisLongitudCentro: lng } =
                        paises[i];
                    if (
                        Math.abs(lat - userLat + lng - userLng) <
                        Math.abs(Latitud + Longitud)
                    ) {
                        paisIdSelected = paises[i].paisId;
                        Latitud = lat - userLat;
                        Longitud = lng - userLng;
                    }
                }
                resolve(paisIdSelected)
            }, (err) => {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                resolve(paises[0].paisId)
            });
        }));
        selectPais(paisId);
        setIsLoading(false);
        return paisId;
    }, [credentials]);

    const getTiposDeDocumento = useCallback(async (paisId) => {
        if (!paisId) return;
        setIsLoading(true);
        const { result, status } = await tipoDeDocumentoGet(
            paisId,
            credentials
        );
        if (status.code !== 1) {
            setIsLoading(false);
            return;
        }
        setTiposDeDocumento(result);
        selectTipoDeDocumento(result[0]);
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        if (Object.keys(credentials).length === 0) return;
        (async () => {
            const paisId = await getPais();
            await getTiposDeDocumento(paisId);
        })()
    }, [credentials, getPais, getTiposDeDocumento]);

    const selectPais = (paisId) => {
        formikRef.current.setFieldValue("pais", paisId)
    }

    const selectTipoDeDocumento = (tipoDeDocumento) => {
        formikRef.current.setFieldValue("tipoDeDocumento", tipoDeDocumento.tipoDeDocumentoCod);
        setPlaceholderDNI(tipoDeDocumento.tipoDeDocumentoPlaceholder)
        setRegexDNI(tipoDeDocumento.tipoDeDocumentoRegExp)
    }

    const handleChangePais = (event) => {
        const paisId = event.target.value;
        selectPais(paisId)
        getTiposDeDocumento(paisId);
    }

    const handleChangeTipoDeDocumento = (event) => {
        const tipoDeDocumentoCod = event.target.value;
        const tipoDeDocumento = tiposDeDocumento.find(td => td.tipoDeDocumentoCod === tipoDeDocumentoCod)
        selectTipoDeDocumento(tipoDeDocumento);
    }

    const handleConnection = async (values) => {
        const response = await RNConnectionIns(
            {
                servicioHooliCod: servicio.servicioCod,
                rolCod: values.rol,
                usuarioNroDeDocumentoX: values.dni,
                usuarioSexo: values.genero,
                usuarioMail: null,
                usuarioCelular: null,
                name: values.name,
                lastName: values.lastName,
            },
            credentials
        );
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setSnackBarInfo({
                message: "Usuario creada correctamente",
                severity: "success",
                open: true,
            });
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                dni: "",
                genero: sexos[0].sexoCod,
                name: "",
                lastName: "",
                rol: roles ? roles[0].rolCod : "",
            }}
            validationSchema={
                yup.object({
                    dni: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .matches(regexDNI, { message: "Número de documento inválido" }),
                    rol: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .oneOf(roles.map((rol) => rol.rolCod)),
                })
            }
            onSubmit={(values) => handleConnection(values)}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="name"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="lastName"
                            labelText="Apellido"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="dni"
                            placeholder={placeholderDNI}
                            labelText="Número de DNI"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="sexo"
                            placeholder={placeholderDNI}
                            labelText="Género"
                        >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                    key={sexoCod}
                                    data-id={sexoCod}
                                    value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="rol"
                            labelText="Rol"
                        >
                            {roles.map(({ rolCod, rolNombre }) => (
                                <option
                                    key={rolCod}
                                    data-id={rolCod}
                                    value={rolCod}
                                >
                                    {rolNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>}

                    {step.sexo && <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="sexo"
                            labelText="Género"
                        >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                    key={sexoCod}
                                    data-id={sexoCod}
                                    value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>}

                    {step.nacimiento && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="fechaNacimiento"
                            labelText="Fecha de nacimiento"
                            type="date"
                        />
                    </>}

                    {step.contacto && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre y apellido"
                            placeholder="Juan Galvan"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="email"
                            labelText="Mail"
                            placeholder="ejemplo@gmail.com"
                        />
                    </>}

                    <FormButton
                        inRequest={inRequest || isLoading}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
